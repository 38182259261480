.topTools{
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
.topTools .topSalir,
.topTools .topSalir:hover,
.topTools .topSalir:focus
{
    text-decoration: none;
    color: #ffffff;
    padding-right: 5px;
    outline: none;
    cursor: pointer;
}