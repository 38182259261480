.ingreso {
    min-height: 100vh;
    background-color: #1A1A1A;
}

.ingreso .info-top {
    position: relative;
    padding: 35px;
    background-color: #FFFFFF;
}
.ingreso img {
    margin: 5px auto;
}
.ingreso h1 {
    font-family: Montserrat;
    font-size: 26px;
    color: #F2C714;
    text-align: center;
    font-weight: bold;
    margin: 1.75% 0 1.5%;
}
.ingreso p {
    max-width: 90%;
    text-align: center;
    font-size: 14px;
    font-family: Helvetica;
    margin: 0 auto;
    opacity: 0.875;
}
.ingreso a {
    color: #F2C714;
    font-size: 14px;
    font-family: Helvetica;
    font-weight: bold;
}
.ingreso .arrow{
    background-color: #F2C714;
    border-radius: 50%;
    color: #000;
    position: absolute;
    bottom: -17px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 30px;
    height: 30px;
    padding-top: 6px;
    text-align: center;
}
.ingreso .login-form,
.ingreso .olvide-contrasena {
    background: #1A1A1A;
    color: white;
    padding: 35px;
    text-align: center;
}
.ingreso .login-label {
    display: flex; 
    font-weight: 100;
  }
.ingreso .form-control {
    background-color: #1A1A1A;
    border: solid 1px #979797;
    border-radius: 3px;
    padding: 15px 0 15px 47.5px;
    margin-bottom: 20px;
    height: auto;
}
.ingreso ::placeholder {
    color: #D8D8D8;
}
.ingreso .btn-primary {
    background: #F2C714 !important;
    color: #000;
    font-family: Helvetica;
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 0;
    font-weight: bold;
    font-family: Montserrat;
}

.ingreso .btn-primary:hover, .ingreso .btn-primary:focus, .ingreso .btn-primary:active, .ingreso .btn-primary:active:focus{
    color: #000;
}

.ingreso .mensajeError {
    color: red;
    text-align: center;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}

.ingreso .login-form{
    width: 65%;
    margin: 2% auto 0;
}

.ingreso .olvide-contrasena {
    padding: 20px 35px 35px;
}

.ingreso .info-top p{
    font-size: 15px;
}

@media only screen and (max-width: 812px) {

    .ingreso h1 {
        margin: 7.5% 0 6%;
    }

    .ingreso .login-form{
        width: 100%;
        margin: 5% auto 0;
    }

    .ingreso .olvide-contrasena {
        padding: 0px 35px 35px;
    }

    .ingreso .login-form{
        padding: 35px 35px 35px;
    }

    .ingreso .olvide-contrasena p{
        margin-top: 12.5px;
        font-size: 15px;
        opacity: 1;
    }

    .ingreso .olvide-contrasena a{
        font-size: 15px;
    }
}