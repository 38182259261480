@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css);
@import url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Bienvenida {
    background: #000;
    color: #fff;
    width: 100%;
    display: block;
    height: 100vh;
}
.Bienvenida .slider h1,
.Bienvenida .slider p,
.Bienvenida .slider img{
    text-align: center;
    margin: 45px 0;
    color: #fff;
}
.Bienvenida .slider h1{
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    margin: 45px 0 27.5px;
}
.Bienvenida .slider p{
    font-size: 13px;
    font-family: Montserrat;
    max-width: 325px;
    margin: 0 auto;
    line-height: 1.5;
}
.Bienvenida .slider img{
    display: block;
    margin: 0 auto;
    margin-top: 35px;
}
.Bienvenida .slick-dots li button:before{
    padding: 35px 0;
}
.Bienvenida .slick-dots li button:before{
    opacity: 1;
    color: white;
    font-size: 12px;
}
.Bienvenida .slick-dots li.slick-active button:before{
    opacity: 1;
    color: #F2C714;
}
.Bienvenida .comenzar {
    background-color: #F2C714;
    color: #1A1A1A;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 15px;
    padding: 15px 0;
    width: 275px;
    display: block;
    margin: 90px auto 0;
    border: none;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
}

.slick-next, .slick-prev{
    display: none !important;
}

@media only screen and (max-width: 823px) {
    .Bienvenida {
        padding: 0 0 12.5%;
        height: auto;
    }
}

@media only screen and (max-width: 420px) {
    .Bienvenida {
        padding: 0 0 27.5%;
    }

    .Bienvenida .slider h1{
        padding: 0 10px;
    }

    .Bienvenida .slick-dots li button:before{
        padding: 27.5px 0 35px;
    }
}

@media only screen 
    and (max-width : 411px) 
    and (max-height : 823px) {
        .Bienvenida {
            padding: 17.5% 0 35%;
        }
}

@media only screen 
    and (max-width : 411px) 
    and (max-height : 740px) {
        .Bienvenida {
            padding: 5% 0 27.5%;
        }
}

@media only screen and (max-width: 375px) {
    .Bienvenida {
        padding: 0 0 22.5%;
    }
}

@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) {
        .Bienvenida {
            padding: 15% 0 37.5%;
        }
     }



.ingreso {
    min-height: 100vh;
    background-color: #1A1A1A;
}

.ingreso .info-top {
    position: relative;
    padding: 35px;
    background-color: #FFFFFF;
}
.ingreso img {
    margin: 5px auto;
}
.ingreso h1 {
    font-family: Montserrat;
    font-size: 26px;
    color: #F2C714;
    text-align: center;
    font-weight: bold;
    margin: 1.75% 0 1.5%;
}
.ingreso p {
    max-width: 90%;
    text-align: center;
    font-size: 14px;
    font-family: Helvetica;
    margin: 0 auto;
    opacity: 0.875;
}
.ingreso a {
    color: #F2C714;
    font-size: 14px;
    font-family: Helvetica;
    font-weight: bold;
}
.ingreso .arrow{
    background-color: #F2C714;
    border-radius: 50%;
    color: #000;
    position: absolute;
    bottom: -17px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 30px;
    height: 30px;
    padding-top: 6px;
    text-align: center;
}
.ingreso .login-form,
.ingreso .olvide-contrasena {
    background: #1A1A1A;
    color: white;
    padding: 35px;
    text-align: center;
}
.ingreso .login-label {
    display: flex; 
    font-weight: 100;
  }
.ingreso .form-control {
    background-color: #1A1A1A;
    border: solid 1px #979797;
    border-radius: 3px;
    padding: 15px 0 15px 47.5px;
    margin-bottom: 20px;
    height: auto;
}
.ingreso ::-webkit-input-placeholder {
    color: #D8D8D8;
}
.ingreso ::-ms-input-placeholder {
    color: #D8D8D8;
}
.ingreso ::placeholder {
    color: #D8D8D8;
}
.ingreso .btn-primary {
    background: #F2C714 !important;
    color: #000;
    font-family: Helvetica;
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 0;
    font-weight: bold;
    font-family: Montserrat;
}

.ingreso .btn-primary:hover, .ingreso .btn-primary:focus, .ingreso .btn-primary:active, .ingreso .btn-primary:active:focus{
    color: #000;
}

.ingreso .mensajeError {
    color: red;
    text-align: center;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}

.ingreso .login-form{
    width: 65%;
    margin: 2% auto 0;
}

.ingreso .olvide-contrasena {
    padding: 20px 35px 35px;
}

.ingreso .info-top p{
    font-size: 15px;
}

@media only screen and (max-width: 812px) {

    .ingreso h1 {
        margin: 7.5% 0 6%;
    }

    .ingreso .login-form{
        width: 100%;
        margin: 5% auto 0;
    }

    .ingreso .olvide-contrasena {
        padding: 0px 35px 35px;
    }

    .ingreso .login-form{
        padding: 35px 35px 35px;
    }

    .ingreso .olvide-contrasena p{
        margin-top: 12.5px;
        font-size: 15px;
        opacity: 1;
    }

    .ingreso .olvide-contrasena a{
        font-size: 15px;
    }
}
.filtroOpcion {
    background-color: #fff;
    margin: 0 35px 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 5%;
    text-decoration: none;
}
.filtroOpcion:active,
.filtroOpcion:hover,
.filtroOpcion:focus {
    text-decoration: none;
}
.filtroOpcion p {
    color: #F2C714;
    font-size: 15px;
    font-family: Montserrat;
    font-weight: bold;
    padding: 10px;
}
.filtro {
    background-color: #000;
    width: 100vw;
    height: 100vh;
}
.filtro h1 {
    color: #F2C714;
    font-size: 24px;
    font-weight: bold;
    font-family: Montserrat;
    padding: 0;
    margin: 0;
    display: block;
}
.filtro .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}
.registro .infoTop {
    background-color: #000;
    padding-top: 50px;
    padding-bottom: 0;
    position: relative;
}
.registro .infoTop h1{
    margin: 0;
    padding: 0;
    color: #F2C714;
    font-family: Montserrat;
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 12.5px;
}
.registro .infoTop p{
    color: #fff;
    font-family: Helvetica;
    font-size: 14px;
    text-align: center;
}
.registro .infoTop .numbers {
    display: flex;
    justify-content: space-around;
    position: relative;
    top: 20px;

}
.registro .numbers > div {
    width: 35px;
    height: 35px;
    background-color: #F9F9F9;
    color: #000;
    border-radius: 20px;
    text-align: center;
    padding-top: 7px;
    font-weight: bold;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    outline: none;
}
.registro .numbers > div.active {
    background: #F2C714;
}
.registro .numbers > div.checked {
    text-indent: -9999px;
    background-color: #F2C714;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAANCAYAAAEBGQTXAAAABGdBTUEAALGPC/xhBQAAAXtJREFUKBWFkjFLw1AQx/OSqK0dRXDsVxC6CU1DQegiKBbRoaJDFkH8CF3EwbWbiIhUHAQRcVEIdnHrLP0CDh0Eqz5xaOLvgglJrXhw5P73v7v/vfdiGD+mKpXKThiGh3HCMBzHORek4hQldhAErxEGrMZE5lutVmeSBDPeI0AQ4jphRoNEJU2wkCz2Rq6gaP8iuOh0Opu1Wm1Ka/0CVuBpaYomlMvlDaVUG6whCkL8a67remP1IY458bZZr9cn02NY6koIy7J27X6//8yms+jKGX1il+KG7/tnsrWGCEg+kSzRsQRxI9MiTSkgzlPkPmBCZAytUiYBGLvtaFEaM6TFCmuo2Faa+CtuNpsmxe1isXhK44LUgfflPeaID1h1a7TZ87yJXq93Td7B5VnkbHu8xBFfQ24jlAD7wG+54PVut5sbDAb34Hk8j3+i1EDgkjix+LZEtYXLdPm5bDyHa652mau9I/5lmQtDdWU4HJ6YpmnhizQ9/upIJb4BmkaKDmmMwx4AAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    background-position: 10px;
}
.registro .content {
    padding: 30px 15px 0 15px;
}

.registro .content h1 {
    color: #F2C714;
    font-size: 20px;
    font-weight: bold;
    font-family: Montserrat;
    margin-bottom: 20px;
}

.registro .content h1.success-title{
    color: #000;
}

.registro .content h4 {
    color: #1A1A1A;
    font-size: 17px;
    font-weight: bold;
    font-family: Helvetica;
    margin-top: 15px;
}

.registro .content a:not(.btn){
    color: #4796FC;
    font-weight: bold;
}

.registro .content h5 {
    color: #1A1A1A;
    font-size: 15px;
    font-weight: bold;
}
.registro .content p {
    color: #999999;
    font-size: 13px;
    font-weight: bold;
    font-family: Helvetica;
}
.registro input[type=text],
.registro input[type=number],
.registro input[type=password],
.registro input[type=email]
{
    border: none;   
    border-bottom: solid 1px black;
    width: 100%;
    font-size: 16px;
    font-family: Helvetica;
    color: #3F3F3F;
    margin-bottom: 17.5px;
    outline: none;
    padding: 1% 0.5% 0.5%;
}
.registro input[type=text]::-webkit-input-placeholder,
.registro input[type=password]::-webkit-input-placeholder,
.registro input[type=email]::-webkit-input-placeholder{
    font-size: 16px;
    font-family: Helvetica;
    color: #3F3F3F;
}
.registro input[type=text]::-ms-input-placeholder,
.registro input[type=password]::-ms-input-placeholder,
.registro input[type=email]::-ms-input-placeholder{
    font-size: 16px;
    font-family: Helvetica;
    color: #3F3F3F;
}
.registro input[type=text]::placeholder,
.registro input[type=password]::placeholder,
.registro input[type=email]::placeholder{
    font-size: 16px;
    font-family: Helvetica;
    color: #3F3F3F;
}
.registro .buttons a {
    background-color: white;
    color: #000;
    width: 47.5%;
    font-family: Montserrat;
    font-size: 14px;
    padding: 17.5px;
    border-radius: 4px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
}
.registro .buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 35px;
    margin-top: 35px;
}
.registro .btn-default{
    background: #fff;
}
.registro .btn-primary{
    background: #F2C714 !important;
    width: 47.5%;
    margin-right: 3px;
    color: #000000;
    border: none;
    padding: 18px;
    font-weight: bold;
    border-radius: 4px;
    font-size: 14px;
    font-family: Montserrat; 
}
.registro .btn-primary.white{
    background: #ffffff !important;
    border: 1px solid #000000;
    color: #000;
}
.registro .icon {
    float: right;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 65px;
}
.registro select {
    border: none;
    background-color: transparent;
    border-bottom: solid 1px #3F3F3F;
    width: 100%;
    border-radius: 0;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAELY03+AAAABGdBTUEAALGPC/xhBQAAAE9JREFUCB1j+HxcrOHTcZH/DGCCAQhAIiCaES4C4sEBTANMFkMZIwNTI1g1SAUIwwxEMQLGYQQxQCr+M/yrhwki0yDjeC1fNSCLgTXgMh4AlLszsG1mvGAAAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    background-position: right 12.5px;
    border-right: solid 10px transparent;
    margin-bottom: 17.5px;
    padding: 1% 0% 0.5%;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 16px;
}
.registro select::-ms-expand {
    display: none;
}
.registroNuevoIdioma {
    margin-top: 15px;
}
.habilidadCategoria span.boton{
    float: right;
    font-weight: 16px;
    font-weight: bold;
    cursor: pointer;
}
.habilidadCategoria hr {
    padding: 0;
    margin: 7.5px 0 0;
}
.habilidadLista {
    display: none;
    padding: 1% 0 0;
}
.habilidadLista > span {
    padding: 9.5px 10px;
    margin: 5px;
    border: solid 1px #000;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    font-weight: normal;
}
.registro.final .imagenListo{
    text-align: center;
    margin: 35px auto;
    display: block;
}
.registro.final .content h1 {
    color: #1A1A1A;
    text-align: center;
}
.registro.final .content p {
    text-align: center;
    color: #3F3F3F;
}
.registro .error {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: red;
}
.registro.paso1,
.registro.paso2,
.registro.paso3,
.registro.paso4,
.registro.paso5,
.registro.paso6{
    /* display: none; */
}
.registro.show {
    display: block;
}

.registro .btn.agregar{
    float: right;
}
.registro .nuevaHabilidad,
.registro .nuevaHabilidad:hover {
    color: #4796FC;
    font-family: Helvetica;
    font-size: 12px;
    text-decoration: none;
}
.registro .agregarHabilidadContainer{
    padding: 25px 0;
    display: none;
}
.registro.paso5 label {
    margin-right: 15px;
}
.registro.paso6 .content {
    text-align: center;
}
.registro .habilidadSeleccionada {
    background: #000;
    color: #fff;
    border-radius: 4px;
    padding: 10px;
    width: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: inline-block;
    font-family: Montserrat;
}
.registro .habilidadesSeleccionadas{
    padding: 15px;
    clear: both;
    width: 100%;
    display: block;
}

.habilidadCategoria{
    padding: 1.5% 0;
    font-weight: bold;
    font-size: 15.5px;
}

.registro .content label{
    font-weight: normal;
}

.flex-labels, .flex-labels label, .registro.paso5 .content label{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.flex-labels label{
    margin-right: 15px;
    width: 50px;
}

.flex-labels label input, .registro.paso5 .content label input{
    margin: 0 5px 0 0;
}

.flex-labels label input{
    width: 25px;
    margin: 0;
}

.flex-labels{
    margin: 15px 0;
}

.registro.paso5 .content label{
    font-size: 15px;
}

select:focus{
    outline: none;
}

.registro .content p.form-subtitle{
    font-weight: bold;
    font-family: Montserrat;
    color: #000;
    font-size: 15px;
}

.add-abilities-container{
    display: none;
    margin-bottom: 55px;
}

.add-ability-option{
    margin: 10px 0 0;
    display: block;
}

.add-abilities-container input{
    border: none;   
    border-bottom: solid 1px black;
    width: 43.5%;
    font-size: 16px;
    font-family: Helvetica;
    color: #3F3F3F;
    outline: none;
    padding: 1% 0.5% 0.5%;
}

.add-abilities-container button{
    background: #4796FC;
    font-weight: bold;
    border-radius: 2px;
    border: none;
    padding: 0.75%;
    width: 10%;
    margin-left: 10px;
    color: #fff;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.2);
}

.select-component input {
    margin-bottom: 0 !important;
}


@media only screen and (max-width: 812px) {
    .registro input[type=text], .registro input[type=password], .registro input[type=email]{
        padding: 1.75% 1%;
        margin-bottom: 22.5px;
    }

    .registro select{
        padding: 1.75% 0;
    }

    .registro .content h1 {
        font-size: 24px;
    }

    .registro .content p {
        font-size: 13.5px;
    }

    .habilidadCategoria hr{
        margin: 12.5px 0 10px;
    }

    .habilidadLista > span {
        white-space: normal;
    }

    .registro .content h4{
        margin-bottom: 17.5px;
    }
}
.Spinner_loader____PlO,
.Spinner_loader____PlO:before,
.Spinner_loader____PlO:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: Spinner_load7__2PaBr 1.8s infinite ease-in-out;
  animation: Spinner_load7__2PaBr 1.8s infinite ease-in-out;
}
.Spinner_loader____PlO {
  color: #f2c714;
  font-size: 10px;
  margin: 120px auto;
  position: absolute;
  left: 0;
  right: 0;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Spinner_loader____PlO:before,
.Spinner_loader____PlO:after {
  content: '';
  position: absolute;
  top: 0;
}
.Spinner_loader____PlO:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Spinner_loader____PlO:after {
  left: 3.5em;
}
@-webkit-keyframes Spinner_load7__2PaBr {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes Spinner_load7__2PaBr {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.topTools{
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
.topTools .topSalir,
.topTools .topSalir:hover,
.topTools .topSalir:focus
{
    text-decoration: none;
    color: #ffffff;
    padding-right: 5px;
    outline: none;
    cursor: pointer;
}
.magia {
    background-color: green;
    color: yellow;
}

.container{
    padding-top: 137.5px;
}

.puestos {
    padding: 50px 15px 85px;
    width: 90%;
    margin: 0 auto;
}
.puestos h1 {
    padding-left: 10px;
    margin-left: 10px;
    border-left: solid 2px #F2C714;
    font-size: 21px;
    font-family: Helvetica;
    margin-top: 20px;
    display: block;
    margin-bottom: 15px;
}
.mensajeBlue {
    background-color: #4796FC;
    color: rgba(255,255,255,0.9);
    font-size: 12px;
    font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.2), 0 1px 2px rgba(0,0,0,0.3);
    margin-bottom: 5px;
}
.puestos .mensajeBlue a,
.puestos .mensajeBlue a:active,
.puestos .mensajeBlue a:hover,
.puestos .mensajeBlue a:focus{
    outline: none;
    text-decoration: none;
    cursor: pointer;
}
.puestos .mensajeBlue a,
.puestos .mensajeBlue a:active,
.puestos .mensajeBlue a:hover,
.puestos .mensajeBlue a:focus {
    color: #fff;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

.puestos .mensajeBlue i {
    cursor: pointer;
}

.mensajeBlue span:last-child{
    cursor: pointer;
}

.puestos .filterBox {
    width: 100%;
    border: solid 1px gray;
    padding: 1.25% 1.5% 1.25% 2.5%;
    margin-top: 15px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}
.puestos .searchControl {
    position: relative;
}
.puestos .filtroIcon {
    position: absolute;
    right: 15px;
    top: 30px;
    cursor: pointer;
}
.listadoFiltros{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
}
.listadoFiltros .filtroTab{
    text-align: center;
    color: #000000;
    width: 100%;
    border-bottom: 1px solid #D8D8D8;
    font-family: Montserrat;
    font-size: 15px;
    cursor: pointer;
    padding: 1% 0;
}
.listadoFiltros .filtroTab.active{
    font-weight: bold;
    border-bottom: 7px solid #F2C714;
}
.puestos .legend {
    margin: 15px 0 30px;
    display: block;
    font-size: 16px;
}
/* Puesto item */
.PuestoBlockContent{
    margin-left: 10px;
    width: 100%;
}
.PuestoBlockContent ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    float: right;
    width: 100px;
    list-style: none;
}
.PuestoBlockContent ul li{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: none;
    border: solid 1px #F2C714;
}
.PuestoBlockContent ul li.active{
    background-color: #F2C714 ;
    border: solid 1px #F2C714;
}
.PuestoBlock{
    display: flex;
    position: relative;
    padding: 1.5% 1.25% 0.75%;
    border-bottom: 1px solid rgba(0,0,0,0.125);
}
.PuestoBlock:hover{
    background: #1A1A1A;
    color: #fff;
}
.PuestoBlock:hover h3, .PuestoBlock:hover .VerMas i{
    color: #fff;
}
.PuestoBlock >img {
    height: 54px;
    margin-right: 3px;
}

.PuestoBlock figure{
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1), 0 2px 2px rgba(0,0,0,0.2);
}
.PuestoBlock h3 {
    margin: 0 0 5px;
    padding: 0;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: bold;
    color: #000;
}
.PuestoBlock p {
    padding: 0;
    margin: 0 0 5px;
    font-size: 14px;
    font-family: Montserrat;
}
.PuestoBlock span {
    font-size: 14px;
    color: #999999;
    font-weight: bold;
    margin: 0 0 5px;
    display: block;
}
.PuestoBlock p:not(.afinidad) span {
    display: block;
    margin: 4px 2.5px;
}
.PuestoBlock span.AfinidadIndicador{
    margin: 0;
}
.PuestoBlock span i{
    margin: 0 5px 0 1.5px;
}
.PuestoBlock .afinidad {
    font-size: 13.75px;
    font-family: Montserrat;
    width: 26%;
}
.PuestoBlock hr {
    margin-top: 5px;
    margin-bottom: 5px;
}
.AfinidadIndicador {
    margin-left: 5px;
    top: 1px;
    position: relative;
}
.AfinidadIndicador span {
    border: solid 1px #F2C714;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 7px;
    margin-bottom: 0;
    display: inline-block;
}
.AfinidadIndicador span:first-child {
    margin-left: 10px;
}
.AfinidadIndicador span.active {
    background-color: #F2C714;
}

.blockLink,
.blockLink:hover {
    color: black;
    text-decoration: none;
}

.VerMas {
    position: absolute;
    right: 3px;
    top: 50px;
    width: 55px;
    font-weight: bold;
    color: #4796FC;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.PuestoBlock p .VerMas span{
    display: none;
    margin: 0;
    color: #4796FC;
}

.VerMas i{
    font-size: 18px;
    color: #000;
    display: inline;
    margin: 0;
}

/**/

.flex{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
}

.nowrap{
    flex-flow: row nowrap; 
}

.flex-column{
    flex-flow: column wrap;
}

.flex-center{
    align-content: center;
    align-items: center
}

.justify-center{
    justify-content: center;
}

.justify-start{
    justify-content: flex-start;
}

.job-detail figure{
    width: 250px;
    height: 180px;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.job-detail figure img{
    width: 100%;
}

.job-detail > div{
    flex-basis: 75.75%;
}

.job-detail > div h3{
    font-size: 38px;
    font-weight: bold;
}

.job-detail > div h3, .job-detail p{
    margin: 5px 0 10px 0;
}

.company-name, .mensajeBlue{
    font-family: Montserrat;
}

.company-name{
    font-size: 22px;
}

.job-detail p.location{
    font-size: 18px;
    margin: 5px 0;
}

.location span{
    margin-left: 10px;
    flex-basis: 90%;
    word-wrap: break-word;
    color: #999999;
    font-weight: bold;
}

.job-detail .distancia {
    font-family: Helvetica;
    font-size: 15px;
    color: #999999;
}
.job-detail .distancia{
    margin-left: 20px;
    color: #999999;
    font-weight: bold;
}

.mensajeBlue.large-message{
    margin: 0 0 2.25%;
}

.job-status p, .afinidad{
    font-size: 17px;
}

.job-status p:not(:nth-child(3)){
    font-weight: bold;
    font-size: 18px;
}

.job-info-icons div{
    flex-basis: 50%;
}

.job-info-icons{
    padding: 4.5% 0;
}

.job-info-icons figure{
    margin-bottom: 2.5%;
}

.job-info-icons p{
    margin-bottom: 0.5%;
}

.job-info-icons div.info-with-border{
    border-right: 3px solid #F2C714;
}

.job-info-icons div{
    min-height: 82px;
}

.job-status p.requirement{
    font-weight: normal;
}

.job-status p span{
    margin: 0 7.5px 0 5px;
}

.afinidad{
    margin: 1.5% 0 1%;
}

.job-status:nth-child(2) p:first-child{
    margin: 1.5% 0;
}

.job-detail{
    margin: 0 0 2.25%;
}

/**/

.detalleContainer {
    min-height: 100vh;
}

.job-status p span{
    color: #F2C714;
    font-weight: bold;
}

.affinities-container{
    list-style-type: none;
    padding-left: 0px;
    justify-content: flex-start;
}

.affinities-container li:not(:last-child){
    padding: 0.65% 1.25%;
    background: #1A1A1A;
    color: rgba(255,255,255,0.9);
    border-radius: 3px;
    text-align: center;
    font-size: 13.5px;
}

.affinities-container li{
    margin: 0.5% 1.5% 1% 0;
}

.affinities-container li:last-child{
    color: #4897FC;
    font-weight: bold;
    cursor: pointer;
    visibility: hidden;
}

.affinities-container li:last-child.active{
    visibility: visible;
}

.job-actions, .day-event-container{
    background: #eeeeee;
    padding: 5.5% 0;
    margin: 2.5% auto;
}

.job-actions button:first-child{
    background: transparent;
    border: none;
    color: #000;
    font-weight: bold;
    order: 3;
}

.job-actions button:first-child span{
    border-bottom: 1px solid #000;
}

.job-actions button:first-child img{
    padding-left: 10px;
}

.job-actions button:last-child, .day-event-container button{
    width: 50%;
    background: #F2C714;
    color: #000;
    border-radius: 6px;
    border: none;
    padding: 2%;
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
}

.day-event-container input{
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.25);
    padding: 1%;
    width: 50%;
    margin-bottom: 1%;
}

a:hover{
    text-decoration: none !important;
}

.mensajeBlue:hover{
    color: #fff;
}

.near-me{
    margin-bottom: 30px;
}

.no-jobs{
    text-align: center;
    font-weight: bold;
    margin: 50px 0;
}

.PuestoBlock p:not(.afinidad) span.job-span-location{
    margin: 4px 0 2.5px;
    width: 100%;
}

@media only screen and (max-width: 812px) {

    .job-detail .distancia {
        font-size: 14px;
        margin-left: 22.5px;
    }

    .job-actions, .day-event-container{
        padding: 5% 0 7.5%;
        margin: 2.5% auto;
    }

    .day-event-container{
        padding: 5% 0 17.5%
    }

    .PuestoBlock figure{
        width: 50px;
        min-width: 50px;
        height: 50px;
    }

    .container{
        padding-top: 127.5px;
    }

    .detalleContainer div:nth-child(2){
        margin-bottom: 5px;
    }

    .back-image{
        width: 1.75%;
    }

    .job-detail{
        padding: 2% 3.5%;
        margin: 0;
    }

    .job-detail figure{
        width: 100px;
        height: 100px;
    }

    .job-detail figure img{
        width: 100%;
    }

    .job-detail > div{
        flex-basis: 69%;
    }

    .job-detail > div h3, .job-detail p{
        margin: 0 0 7.5px 0;
    }

    .job-detail > div h3{
        font-size: 22px;
    }

    .location, .company-name{
        font-size: 14px;
    }

    .location span{
        margin-left: 7.5px;
    }

    .job-detail p.location{
        margin: 0 0 2.5px 0;
    }

    .mensajeBlue.large-message{
        width: 92.5%;
        margin: 5.5% auto;
        padding: 7.5px 10px;
    }

    .mensajeBlue.large-message span:first-of-type{
        flex-basis: 82.5%;
    }

    .mensajeBlue.large-message span:last-of-type{
        align-self: flex-start;
    }

    .job-information{
        padding: 0 5% 5%;
    }

    .job-info-icons figure{
        margin-bottom: 5%;
    }

    .job-status p, .afinidad{
        font-size: 14px;
    }

    .affinities-container li:not(:last-child){
        padding: 2.5% 3%;
    }

    .affinities-container li{
        margin: 0;
    }

    /* .affinities-container li:nth-child(3n+2), .affinities-container li:last-child{
        margin: 1.5% 3%;
    } */

    .affinities-container li{
        margin: 1.5% 3% 1.5% 0;
    }

    .job-actions{
        padding: 7.5% 0 20%;
    }

    .job-actions button:last-child, .day-event-container button{
        width: 75%;
        padding: 4.25%;
    }

    .day-event-container input{
        width: 75%;
        padding: 2.5%;
        text-align: center;
        margin-bottom: 2.5%;
    }

    .job-actions button:first-child{
        margin-bottom: 5%;
    }

    .afinidad{
        margin: 5% 0 2.5%;
    }

    .job-status:nth-child(2) p:first-child{
        margin: 5% 0;
    }

    .job-info-icons{
        padding: 6.5% 0;
    }

    .job-status p:not(:nth-child(3)){
        font-size: 16px;
    }

    .listadoFiltros .filtroTab{
        padding: 1.75% 0;
    }

    .listadoFiltros{
        margin-top: 20px;
    }

    .puestos {
        padding: 50px 15px 75px;
        width: 100%;
    }

    .puestos .filterBox {
        padding: 3% 1.5% 3% 4%;
        margin-top: 15px;
    }

    .puestos .filtroIcon {
        right: 10px;
        top: 25px;
    }

    .PuestoBlock{
        padding: 3.5% 2.5% 1.5%;
    }

    .PuestoBlock h3{
        font-size: 15px;
        margin: 0 0 7.5px;
    }

    .PuestoBlock p {
        font-size: 14px;
        margin: 0 0 4px;
    }

    .PuestoBlock span {
        font-size: 13px;
        margin: 0 0 4px;
    }

    .PuestoBlock p:not(.afinidad) span {
        display: block;
        margin: 4px 0 0;
    }

    .PuestoBlock .afinidad {
        font-size: 13px;
        width: 100%;
    }

    .puestos .legend {
        font-size: 15px;
    }

    .PuestoBlock p:not(.afinidad) .VerMas{
        position: relative;
        top: auto;
        right: auto;
    }

    .PuestoBlock p:not(.afinidad) .VerMas span{
        margin: 0;
    }

    .PuestoBlock p:not(.afinidad) .VerMas i{
        font-size: 16px;
    }

    .PuestoBlock p:not(.afinidad) span.job-span-location{
        margin: 5px 0 2.5px;
    }
}

@media only screen and (max-width: 420px) {
    .job-detail > div{
        flex-basis: 65%;
    }

    .job-detail p.location{
        width: 100%;
    }

    .day-event-container{
        padding: 5% 0 22.5%
    }
}

.bottomTools {
    display: flex;
    position: fixed;
    justify-content: space-around;
    align-items: flex-start;
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    bottom: 0px;
    left: 0px;
}

.bottomTools a:active,
.bottomTools a:focus,
.bottomTools a:hover,
.bottomTools a.active,
.bottomTools a{
    text-align: center;
    color: white;
    padding: 16.75px 0;
    flex-basis: 25%;
    border-top: 3px solid #000000;
}

.bottomTools a.active{
    border-top: 3px solid #F2C714;
}


/* .bottomTools a.active {
} */

.Filtros_backdrop__1WPbx {
    position: fixed;
    top: 0;
    left: 0;
    padding: 18px;
    z-index: 100;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0, 0.5);
    /* filter: blur(8px);
    -webkit-filter: blur(8px); */
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    overflow-y: scroll;
}

.Filtros_modal__kL7fz {
    background-color: #FFFFFF;
    width: 62.5%;
    margin: 0 auto;
    /* height: 100%; */
    cursor: auto;
    filter: none;
    -webkit-filter: none;
    padding: 20px 20px 40px; 
    margin-bottom: 18px;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 1px solid rgba(0,0,0,1);
}

.Filtros_header__2BYh5 {
    position: relative;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #F2C714;
    padding-bottom: 10px;
}

.Filtros_content__u91V2 {
    padding: 24px 8px 0;
}

.Filtros_button__2SLKo {
    background-color: #F2C714;
    border: none;
    border-radius: 9px;
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    color: #1A1A1A;
    height: 52px;
    margin-top: 10px;
    font-weight: bold;
    font-family: Montserrat;
}

.Filtros_header__2BYh5 h2 {
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
    color: #000000;
    margin: 0;
}

.Filtros_close__3FFpT {
    width: 100%;
    cursor: pointer;
}

.Filtros_content__u91V2 section {
    padding: 0px 21px 37px 30px;
    position: relative;
}

.Filtros_sectionIcon__jsKr3 {
    position: absolute;
    height: 16px;
    /* width: 20px; */
    left: 0px;
}

.Filtros_content__u91V2 section h3 {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 3px 0 10px;
}

.Filtros_content__u91V2 label {
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}

.Filtros_content__u91V2 input[type="checkbox"] {
    margin-right: 10px;
    margin-top: 0;
    vertical-align: bottom;
    /* font-size: 18px;
    width: 18px;
    height: 18px; */
}

/* .content input[type="checkbox"]:after {
    content: " ";
    margin-right: 10px;
    border: 2px solid #8D8D8D;
    background-color: #FFFFFF;
    display: inline-block;
    visibility: visible;    
    width: 18px;
    height: 18px;
} */

.Filtros_chipsWrapper__gTuaa {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 16px;
}

.Filtros_chip__3VLNq {
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    min-width: 77.5px;
    cursor: pointer;
    border: 1px solid #1A1A1A;
    margin: 4px 0;
    margin-right: 7px;
    padding: 15px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.175), 0 1px 2px rgba(0,0,0,0.25);
}

.Filtros_chip__3VLNq.Filtros_selected__2N21K {
    background-color: #1A1A1A;
}

.Filtros_chip__3VLNq span {
    color: #1A1A1A;
    font-size: 12px;
    line-height: 14px;
}

.Filtros_chip__3VLNq.Filtros_selected__2N21K span{
    color: #FFFFFF;
}

.Filtros_sliderContainer__APD0t {
    width: 100%;
}

.Filtros_sliderContainer__APD0t p {
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 11px;
}

.Filtros_sliderLabels__3sBnT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 6px;
}

.Filtros_sliderLabels__3sBnT span {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
}

.Filtros_closeFiltersX__28cY6{
    position: relative;
    width: 3%;
}

.Filtros_closeFiltersX__28cY6::after{
    content: "x";
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 1px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

.Filtros_experienceContainer__1Ku7p{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

.Filtros_experienceContainer__1Ku7p div{
    flex-basis: 25%;
    white-space: nowrap;
    margin: 2% 0 0 0;
}

.Filtros_experienceContainer__1Ku7p input {
    border: none;
    width: 52.5%;
    border-bottom: 2px solid #cccccc;
    margin: 0 10px 0 20px;
    text-align: center;
}

.Filtros_experienceContainer__1Ku7p input:focus {
    outline: none;
    border-bottom-color: #F2C714;
}

@media only screen and (max-width: 812px) {
    .Filtros_modal__kL7fz {
        width: 70%;
        margin: 0 auto;
    }

    .Filtros_closeFiltersX__28cY6{
        width: 4.75%;
    }

    .Filtros_closeFiltersX__28cY6::after{
        top: 0.5px;
        font-size: 12.25px;
    }

    .Filtros_experienceContainer__1Ku7p div{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 420px) {
    .Filtros_modal__kL7fz {
        width: 100%;
        padding: 20px 10px 40px;
    }

    .Filtros_content__u91V2 section {
        padding: 0px 21px 37px 21px;
    }

    .Filtros_backdrop__1WPbx {
        padding: 15px 12px;
    }

    .Filtros_closeFiltersX__28cY6{
        width: 7%;
    }

    .Filtros_closeFiltersX__28cY6::after{
        top: 0.5px;
        font-size: 12.25px;
    }

    .Filtros_experienceContainer__1Ku7p div{
        flex-basis: 42.5%;
    }

    .Filtros_experienceContainer__1Ku7p input {
        margin: 0 12.5px;
        width: 55%;
    }
}

@media only screen and (max-width: 375px) {
    .Filtros_closeFiltersX__28cY6::after{
        top: 0px;
    }
}

.Stars_container__2OSPI {
    display: flex;
    flex-direction: row;
    align-items: space-around;
}

.Stars_container__2OSPI img {
    margin: 0 10px;
    height: 30px;
}
.Modal_backdrop__12skI {
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 100;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0, 0.5);
    /* filter: blur(8px);
    -webkit-filter: blur(8px); */
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    /*overflow: scroll;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal_modal__2wUxT {
    background-color: #FFFFFF;
    width: 50%;
    cursor: auto;
    filter: none;
    -webkit-filter: none;
    padding: 40px 15px 37px; 
    margin-bottom: 18px;
    border: 1px solid #979797;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}

.Modal_modal__2wUxT h1 {
    font-size: 20px;
    line-height: 24px;
    color: #1A1A1A;
    text-align: center;
    margin: 23px 0;
    font-weight: bold;
}

.Modal_modal__2wUxT p {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-align: center;
    margin: 0;
    width: 90%;
}

.Modal_buttonsContainer__2TBF1 {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.Modal_buttonsContainer__2TBF1 button {
    background-color: #F2C714;
    border: none;
    width: 20%;
    height: 37px;
    font-size: 12px;
    color: #1A1A1A;
    line-height: 14px;
    text-transform: uppercase;
    margin: 0 6px;
    font-weight: bold;
    border-radius: 3px;
    font-family: Montserrat;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}

.Modal_buttonsContainer__2TBF1 button.Modal_secondary__Zm2d_ {
    background-color: #FFFFFF;
    color: #1A1A1A;
    border-color: #1A1A1A;
}

.Modal_optionsWrapper__2Se-a {
    padding-top: 21px;
}

.Modal_optionsWrapper__2Se-a label{
    font-size: 16px;
    line-height: 20px;
    color: #3F3F3F;
    padding-bottom: 10px;
    display: block;
    text-align: center;
}

.Modal_optionsWrapper__2Se-a label input {
    margin-right: 10px
}

@media only screen and (max-width: 812px) {
    .Modal_buttonsContainer__2TBF1 button {
        width: 25%;
    }
    .Modal_modal__2wUxT {
        width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    .Modal_buttonsContainer__2TBF1 button {
        width: 45%;
    }
}
.NotFound_ingreso__1KZuR{
    height: 100vh;
}

.NotFound_ingreso__1KZuR .NotFound_info-top__20UH1 {
    position: relative;
    padding: 35px;
    background-color: #FFFFFF;
    text-align: center;
    min-height: 250px;
    max-height: 250px;
}
.NotFound_ingreso__1KZuR img {
    margin: 5px auto 10px;
}
.NotFound_ingreso__1KZuR h1 {
    font-family: Montserrat;
    font-size: 33px;
    color: #F2C714;
    text-align: center;
    font-weight: bold;
    margin: 0;
}
.NotFound_ingreso__1KZuR p {
    /*max-width: 90%;*/
    text-align: center;
    font-size: 13px;
    font-family: Helvetica;
}

.NotFound_arrow__3RNfO{
    background-color: #F2C714;
    border-radius: 50%;
    color: #000;
    position: absolute;
    top: -12.5px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 30px;
    height: 30px;
    padding-top: 6px;
    text-align: center;
}

.NotFound_blackContainer__1NuPY{
    position: relative;
    background: #1a1a1a;
    height: calc(100% - 250px);
    padding: 8% 0 0;
}

.NotFound_button__1RNQ8{
    width: 22.5%;
    background: #F2C714;
    color: #000;
    border-radius: 7px;
    border: none;
    padding: 1.5%;
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 auto;
    display: block;
    text-align: center;
}


@media only screen and (max-width: 420px) {
    .NotFound_ingreso__1KZuR .NotFound_info-top__20UH1 {
        padding: 35px 35px 20px;
        min-height: 230px;
        max-height: 230px;
    }

    .NotFound_button__1RNQ8{
        width: 60%;
        padding: 4% 0;
    }

    .NotFound_blackContainer__1NuPY{
        height: calc(100% - 230px);
        padding: 18% 0 0;
    }
}

.forgot-container{
    height: 100vh;
    background: #1A1A1A;
}

.forgot-container input{
    background-color: transparent;
    border: solid 1px #979797;
    border-radius: 4px;
    padding: 15px 0 15px 47.5px;
    margin-bottom: 20px;
    height: auto;
    width: 100%;
    color: #fff;
    font-family: Montserrat;
    font-weight: bold;
}

.forgot-container form{
    width: 35%;
}

.forgot-container input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
}

.forgot-container input::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
}

.forgot-container input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
}

.forgot-container input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}

.forgot-container input::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
}

.forgot-buttons button{
    width: 47.5%;
    color: #000;
    border-radius: 6px;
    border: none;
    padding: 3.75%;
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
}

.forgot-buttons button:first-child{
    background: #FFF;
}

.forgot-buttons button:last-child{
    background: #F2C714;
}

.forgot-container p{
    width: 100%;
    text-align: center;
    margin: 2.5% 0;
    font-family: Montserrat;
    font-weight: bold;
    color: #fff;
    opacity: 0.8;
}

.forgot-container h1{
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    margin: 0;
}

@media only screen and (max-width: 812px) {

    .forgot-container{
        padding: 15px;
    }

    .forgot-container h1{
        font-size: 28px;
    }

    .forgot-container br{
        display: none;
    }

    .forgot-container form{
        width: 100%;
    }
}
.magia {
    background-color: green;
    color: yellow;
}

.eventos:not(.detalle) {
    padding: 50px 15px 75px;
    width: 90%;
    margin: 0 auto;
}
.eventos h1 {
    padding-left: 10px;
    margin-left: 10px;
    border-left: solid 2px #F2C714;
    font-size: 21px;
    font-family: Helvetica;
    margin-top: 20px;
    display: block;
    margin-bottom: 15px;
}
.eventos .mensajeBlue {
    background-color: #4796FC;
    color: #fff;
    font-size: 12px;
    font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 5px;
    font-family: Montserrat;
}
.eventos .mensajeBlue a,
.eventos .mensajeBlue a:active,
.eventos .mensajeBlue a:hover,
.eventos .mensajeBlue a:focus{
    outline: none;
    text-decoration: none;
    cursor: pointer;
}
.eventos .mensajeBlue a,
.eventos .mensajeBlue a:active,
.eventos .mensajeBlue a:hover,
.eventos .mensajeBlue a:focus {
    color: #fff;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

.eventos .mensajeBlue i {
    cursor: pointer;
}

.eventos .filterBox {
    width: 100%;
    border: solid 1px gray;
    padding: 1.25% 1.5% 1.25% 2.5%;
    margin-top: 15px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}
.eventos .searchControl {
    position: relative;
}
.eventos .filtroIcon {
    position: absolute;
    right: 15px;
    top: 30px;
    cursor: pointer;
}
/* .eventos .listadoFiltros{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;

}
.eventos .listadoFiltros a{
    text-align: center;
    color: #000000;
    width: 100%;
    border-bottom: 1px solid gray;
    font-family: Montserrat;
    font-size: 14px;
}
.eventos .listadoFiltros a.active{
    font-weight: bold;
    border-bottom: 3px solid #F2C714;
} */
.eventos .listadoFiltros{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 25px;
}
.eventos .listadoFiltros .filtroTab{
    text-align: center;
    color: #000000;
    width: 100%;
    border-bottom: 1px solid #D8D8D8;
    font-family: Montserrat;
    font-size: 15px;
    cursor: pointer;
}
.eventos .listadoFiltros .filtroTab.active{
    /* font-weight: bold; */
    border-bottom: 7px solid #F2C714;
}
.eventos .legend {
    margin: 15px 0 30px;
    display: block;
    font-size: 16px;
}
/* Evento item */
.EventoBlockContent{
    margin-left: 10px;
    width: 100%;
}
.EventoBlockContent ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    float: right;
    width: 100px;
    list-style: none;
}
.EventoBlockContent ul li{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: none;
    border: solid 1px #F2C714;
}
.EventoBlockContent ul li.active{
    background-color: #F2C714 ;
    border: solid 1px #F2C714;
}
.EventoBlock{
    display: flex;
    position: relative;
    padding: 1.5% 1.25% 0.75%;
    border-bottom: 1px solid rgba(0,0,0,0.125);
}
.EventoBlock:hover{
    background: #1A1A1A;
    color: #fff;
}
.EventoBlock:hover h3, .EventoBlock:hover .VerMas i{
    color: #fff;
}
.EventoBlock >img {
    height: 54px;
    margin-right: 3px;
}
.EventoBlock figure{
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1), 0 2px 2px rgba(0,0,0,0.2);
}
.EventoBlock h3 {
    margin: 0 0 5px;
    padding: 0;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: bold;
    color: #000;
}
.EventoBlock p {
    padding: 0;
    margin: 0 0 5px;
    font-size: 14px;
    font-family: Montserrat;
}
.EventoBlock span {
    font-size: 14px;
    color: #999999;
    font-weight: bold;
    margin: 0 0 5px;
    display: block;
}
.EventoBlock p:not(.afinidad) span {
    display: block;
    margin: 4px 2.5px;
}
.EventoBlock span.AfinidadIndicador{
    margin: 0;
}
.EventoBlock span i{
    margin: 0 5px 0 1.5px;
}
.EventoBlock .afinidad {
    font-size: 13.75px;
    font-family: Montserrat;
    width: 26%;
}
.EventoBlock hr {
    margin-top: 5px;
    margin-bottom: 5px;
}
.eventos.detalle {
    padding-top: 0;
}
.eventos.detalle .header>img{
    float: left;
    margin: 15px;
}
.eventos.detalle h1{
    border: none;
    margin-top: 15px;
    float: left;
    font-family: Helvetica;
    font-size: 20px;
}
.eventos.detalle .empresa {
    font-family: Montserrat;
    font-size: 12px;
    color: #3F3F3F;
}
.eventos.detalle .direccion,
.eventos.detalle .distancia {
    font-family: Helvetica;
    font-size: 15px;
    color: #999999;
}
.eventos.detalle .distancia{
    margin-left: 20px;
    color: #999999;
    font-weight: bold;
}
.eventos.detalle .content {
    display: block;
    clear: both;
}
.eventos.detalle .content img {
    margin: 0 10px;
}
.medallaContent {
    color: #999999;
    padding-left: 42.5px;
    padding-right: 15px;
    font-size: 14px;
    font-family: Helvetica;
    margin: 0.5% 0 1.5%;
    font-weight: bold;
}
.eventos.detalle .content :not(.medallaTitle) p {
    color: #3f3f3f;
    font-size: 14px;
    font-family: Helvetica;
    font-weight: lighter;
}
.medallaTitle p{
    margin: 0;
}
.medallaTitle p, .eventos.detalle .content p.subTitulo{
    font-weight: bold;
    font-size: 16px;
}
.eventos.detalle .content p.subTitulo{
    margin: 2% 0 0.25%;
}
.eventos.detalle .content p.afinidad{
    font-size: 15px;
    font-weight: bold;
    /* width: 27.5%; */
}
.eventos.detalle .descripcion .subTitulo {
    font-family: Helvetica;
    font-size: 14px;
    color: #1a1a1a;

}
.eventos.detalle .descripcion .afinidad {
    font-size: 12px;
    font-family: Montserrat;
    color: #3f3f3f;
}
.AfinidadIndicador {
    margin-left: 5px;
    top: 1px;
    position: relative;
}
.AfinidadIndicador span {
    border: solid 1px #F2C714;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 7px;
    margin-bottom: 0;
    display: inline-block;
}
.AfinidadIndicador span:first-child {
    margin-left: 10px;
}
.AfinidadIndicador span.active {
    background-color: #F2C714;
}

.blockLink,
.blockLink:hover {
    color: black;
    text-decoration: none;
}

.EventoBlock .VerMas {
    position: absolute;
    right: 3px;
    top: 60px;
    width: 55px;
    font-weight: bold;
    color: #4796FC;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.EventoBlock p .VerMas span{
    display: none;
    margin: 0;
    color: #4796FC;
}

.VerMas i{
    font-size: 18px;
    color: #000;
    display: inline;
    margin: 0;
}
.nav-control {
    background-color: #f9f9f9;
    padding: 15px;
}
.eventos.detalle .habilidades span {
    background-color: #000;
    color: white;
    padding: 10px;
    font-size: 12px;
    font-weight: lighter;
    font-family: Helvetica;
    margin-right: 15px;
}
.eventos.detalle .habilidades .verMas,
.eventos.detalle .habilidades .verMas:active,
.eventos.detalle .habilidades .verMas:hover {
        color: #4796FC;
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
        font-family: Helvetica;
}
.detalles p{
    margin: 0;
}

.eventos.detalle .content .detalles img{
    margin: 0 20px 0 10px;
}

.eventos.detalle .content .detalles p{
    font-size: 14px;
}

.eventos.detalle .content .detalles p.subTitulo{
    font-size: 16px;
    font-weight: normal;
    margin: 0;
}

.eventos.detalle .content .detalles > p.subTitulo{
    font-weight: bold;
}

.medallaTitle{
    margin-top: 1.5%;
}

.detalles > div{
    justify-content: flex-start;
    margin: 2.25% 0;
}

.eventos.detalle .content .detalles img{
    margin: 0 20px 0 0px;
}

.job-actions button:first-child, .job-actions.asistirEvento p{
    margin: 2.5% 0 0;
}

.job-actions.asistirEvento{
    margin-bottom: 0;
    padding: 5% 0;
}

.EventoBlock p:not(.afinidad) span.job-span-location{
    margin: 4px 0 2.5px;
    width: 100%;
}

.dates-event-container p:not(:first-of-type) {
    width: 100%;
    margin: 12.5px 0 12.5px 34px;
}

@media only screen and (max-width: 812px) {

    .dates-event-container p:not(:first-of-type) {
        margin: 12.5px 0 12.5px 44px;
    }

    .eventos.detalle .distancia{
        font-size: 14px;
        margin-left: 22.5px;
    }

    .VerMas {
        top: 35px;
    }

    .VerMas span{
        display: inline;
        font-size: 14px;
    }

    .EventoBlock .VerMas {
        top: 45px;
    }

    .EventoBlock .VerMas span{
        display: inline;
        font-size: 14px;
    }

    .eventos:not(.detalle){
        width: 100%;
    }

    .eventos.detalle .content .medallaContent{
        margin: 0.5% 0 5%;
    }

    .eventos.detalle .content p.subTitulo{
        margin: 6% 0 0.75%;
    }

    .eventos.detalle .content .detalles p.subTitulo{
        margin: 0;
    }

    .job-actions.asistirEvento button:first-child, .job-actions.asistirEvento p{
        margin-bottom: 3.25%;
    }

    .detalles > div{
        margin: 5% 0;
    }

    .eventos.detalle .content .detalles img{
        margin: 0 20px 0 10px;
    }

    .job-actions.asistirEvento{
        padding: 7.5% 0;
    }

    .eventos .filterBox {
        padding: 3% 1.5% 3% 4%;
        margin-top: 15px;
    }

    .eventos .filtroIcon {
        right: 10px;
        top: 25px;
    }

    .EventoBlock{
        padding: 3.5% 2.5% 1.5%;
    }

    .EventoBlock h3 {
        font-size: 15px;
        margin: 0 0 9px;
    }

    .EventoBlock p {
        font-size: 13.5px;
        margin: 0 0 4px;
    }

    .EventoBlock span {
        font-size: 12.25px;
        margin: 0 0 4px;
    }

    .EventoBlock p:not(.afinidad) span {
        display: block;
        margin: 4px 0 0;
    }

    .EventoBlock .afinidad {
        font-size: 13px;
        width: 100%;
    }

    .eventos .legend {
        font-size: 15px;
    }

    .AfinidadIndicador span{
        margin-right: 7px;
        margin-bottom: 0;
    }

    .EventoBlock figure{
        width: 50px;
        min-width: 50px;
        height: 50px;
    }

    .EventoBlock p:not(.afinidad) .VerMas{
        position: relative;
        top: auto;
        right: auto;
    }

    .EventoBlock p:not(.afinidad) .VerMas span{
        margin: 0;
    }

    .EventoBlock p:not(.afinidad) .VerMas i{
        font-size: 16px;
    }

    .EventoBlock p:not(.afinidad) span.job-span-location{
        margin: 5px 0 2.5px;
    }
}
.Filtros_backdrop__2TRKA {
    position: fixed;
    top: 0;
    left: 0;
    padding: 18px;
    z-index: 100;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0, 0.5);
    /* filter: blur(8px);
    -webkit-filter: blur(8px); */
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    overflow-y: scroll;
}

.Filtros_modal__x264x {
    background-color: #FFFFFF;
    width: 62.5%;
    margin: 0 auto;
    /* height: 100%; */
    cursor: auto;
    filter: none;
    -webkit-filter: none;
    padding: 20px 20px 40px; 
    margin-bottom: 18px;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 1px solid rgba(0,0,0,1);
}

.Filtros_header__1QB1Z {
    position: relative;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #F2C714;
    padding-bottom: 10px;
}

.Filtros_content__3fYon {
    padding: 24px 8px 0;
}

.Filtros_button__Unwsh {
    background-color: #F2C714;
    border: none;
    border-radius: 9px;
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    color: #1A1A1A;
    height: 52px;
    margin-top: 10px;
}

.Filtros_header__1QB1Z h2 {
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
    color: #000000;
    margin: 0;
}

.Filtros_close__d9MPd {
    width: 100%;
    cursor: pointer;
}

.Filtros_content__3fYon section {
    padding: 0px 21px 37px 30px;
    position: relative;
}

.Filtros_sectionIcon__O9hk2 {
    position: absolute;
    height: 16px;
    /* width: 20px; */
    left: 0px;
}

.Filtros_content__3fYon section h3 {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 3px 0 10px;
}

.Filtros_content__3fYon label {
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}

.Filtros_content__3fYon input[type="checkbox"] {
    margin-right: 10px;
    margin-top: 0;
    vertical-align: bottom;
    /* font-size: 18px;
    width: 18px;
    height: 18px; */
}

/* .content input[type="checkbox"]:after {
    content: " ";
    margin-right: 10px;
    border: 2px solid #8D8D8D;
    background-color: #FFFFFF;
    display: inline-block;
    visibility: visible;    
    width: 18px;
    height: 18px;
} */

.Filtros_chipsWrapper__1yIJC {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 16px;
}

.Filtros_chip__jJB5P {
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27px;
    min-width: 75px;
    cursor: pointer;
    border: 1px solid #1A1A1A;
    margin: 4px 0;
    margin-right: 7px;
    padding: 15px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.175), 0 1px 2px rgba(0,0,0,0.25);
}

.Filtros_chip__jJB5P.Filtros_selected__3IoKR {
    background-color: #1A1A1A;
}

.Filtros_chip__jJB5P span {
    color: #1A1A1A;
    font-size: 12px;
    line-height: 14px;
}

.Filtros_chip__jJB5P.Filtros_selected__3IoKR span{
    color: #FFFFFF;
}

.Filtros_sliderContainer__2fTy7 {
    width: 100%;
}

.Filtros_sliderContainer__2fTy7 p {
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 11px;
}

.Filtros_sliderLabels__xbQxw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 6px;
}

.Filtros_sliderLabels__xbQxw span {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
}

.Filtros_closeFiltersX__3ikAR{
    position: relative;
    width: 3%;
}

.Filtros_closeFiltersX__3ikAR::after{
    content: "x";
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 1px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}


@media only screen and (max-width: 812px) {
    .Filtros_modal__x264x {
        width: 70%;
        margin: 0 auto;
    }

    .Filtros_closeFiltersX__3ikAR{
        width: 4.75%;
    }

    .Filtros_closeFiltersX__3ikAR::after{
        top: 0.5px;
        font-size: 12.25px;
    }
}

@media only screen and (max-width: 420px) {
    .Filtros_modal__x264x {
        width: 100%;
        padding: 20px 10px 40px;
    }

    .Filtros_content__3fYon section {
        padding: 0px 21px 37px 21px;
    }

    .Filtros_backdrop__2TRKA {
        padding: 15px 12px;
    }

    .Filtros_closeFiltersX__3ikAR{
        width: 7%;
    }

    .Filtros_closeFiltersX__3ikAR::after{
        top: 0.5px;
        font-size: 12.25px;
    }
}

@media only screen and (max-width: 375px) {
    .Filtros_closeFiltersX__3ikAR::after{
        top: 0px;
    }
}

.Oportunidades_container__2cCtI {
    padding-top: 175px;
    text-align: center;
    padding-bottom: 18%;
}

.Oportunidades_container__2cCtI h1 {
    margin: 28px 0 16px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}

.Oportunidades_container__2cCtI p {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #3F3F3F;
}

.Oportunidades_box__2p3dI {
    border: 1px solid #F2C714;
    padding: 20px 7px 8px 11px;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 20px;
}

.Oportunidades_content__nUF9i {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.Oportunidades_content__nUF9i img {
    height: 50px;
    width: 50px;
    margin-right: 9px;
}

.Oportunidades_content__nUF9i h2 {
    font-size: 15px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 18px;
    margin: 0;
}

.Oportunidades_content__nUF9i p {
    color: #3F3F3F;
    font-size: 12px;
    line-height: 17px;
    margin: 5px 0 4px;
}

.Oportunidades_content__nUF9i span {
    color: #626262;
    font-size: 12px;
    line-height: 17px;
}

.Oportunidades_content__nUF9i .Oportunidades_location__3KCSS {
    height: 14px;
    width: 10px;
    display: inline-block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAOCAYAAAFhpL09AAAABGdBTUEAALGPC/xhBQAAAVxJREFUKBVlUbtKA1EQvTNuomASLDaLmEIbLUWxCAGtxQ/QRjsbLbWxsUyjgug/2PkB4hckG4KijaCNJtiEFLJ3A5rHHWeGJMQ4sDtn5pw798yuMaMRlYJLY8vZKxOVfBJ0LbQWAmzZr0kGqq4lWq6+g7bz0XZkcjDUCB9V53zVCC0NCQB4xrhX39cK8YSzJaJlrW0YHMiMP3PiSrArduQ6UQEXT8Mj3Egn5pOoDZ6XKTRBVHG3duEJMM6dRmEgWa5/YZO8n3FHSvJLTmCm0DgeNADxcICNfZjNDjaWpg7/Dv2lDsHtqAslebsE0PZUvvkK4tv13E1/lDWIRcVsiHNaME7gnkfObSihHSxm8o1zgX3XZ4Kp59ZBv2i3/UlkktIcDwDTJi+ZU4/0uDAT/7Te2KM/KuQ/0ExNTi/C6vuXCoVkEcRhcMd5U2oW3afyjS3OJPW/iCvZFXnGiV8krKVNQ/B04wAAAABJRU5ErkJggg==);
    margin-right: 9px;
}

.Oportunidades_box__2p3dI a {
    color: #4796FC;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    float: right;
}

@media only screen and (max-width: 812px) {
    .Oportunidades_container__2cCtI {
        padding-bottom: 22.5%;
    }
}

@media only screen and (max-width: 420px) {
    .Oportunidades_container__2cCtI {
        padding-bottom: 50%;
    }
}
.flex-column{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column wrap;
    align-items: center;
}

.container.medals-page-container, .container.path-container:not(.active){
    padding-top: 137.5px;
}

.container.profile-container.active, .container.path-container.active{
    padding-top: 0px;
}

.profile-general-information{
    margin: 4% 0 0;
}

.profile-general-information h3{
    font-weight: bold;
    font-size: 22px;
}

.profile-general-information .location{
    margin: 0 auto;
}

.profile-general-information .location span {
    margin-left: 5px;
}

.profile-general-information > figure{
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid rgba(0,0,0,0.5);
    width: 185px;
    height: 185px;
    margin: 0 auto;
}

.profile-user-state, .medal-content, .bullying-card{
    font-family: Montserrat;
}

.profile-container{
    background: #F9F9F9;
}
.profile-container.active, .path-container.active{
    width: 100%;
}
.container.path-container.active{
    padding: 0 2.75% 12.5%;
}
.card{
    background: #fff;
    border-radius: 4px;
    padding: 2.5% 2.25%;
    margin: 2.75% 1.5%;
}

.card h5{
    margin: 0 0 3%;
    font-weight: bold;
    border-bottom: 1px solid #F2C714;
    padding-bottom: 1%;
    font-family: Montserrat;
    font-size: 16px;
}

.card h5 span{
    font-weight: normal;
    margin-left: 2.5%;
}

.card-portrait{
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.profile-card-content > div.user-info{
    margin: 0 4.5%;
}

.profile-card-content figure:last-child{
    border-radius: 50%;
    border: 1px solid #F2C714;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 10%;
    box-sizing: border-box;
    padding: 2.25% 0;
    margin-left: 9.5%;
}

.profile-card-content figure:last-child img{
    width: 50%;
}

.medal-image{
    max-width: 45px;
    margin-bottom: 12.5px;
}

.medal-image img{
    width: 100%;
}

.medal-content{
    margin-bottom: 5%;
    font-size: 16px;
    text-align: center;
    flex-basis: 31%;
    margin: 0 1.25%;
    padding: 1.75% 0;
}

.bullying-card .medallaTitle{
    padding: 4% 0 1.5%;
}

.bullying-card img{
    margin: 0 10px;
}

.bullying-card .medallaContent{
    padding-left: 34px;
}

.profile-card.list-card ul {
    list-style: none; /* Remove default bullets */
    padding-left: 20px;
}

.profile-card.list-card ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #F2C714; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */ 
    width: 1.5em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.profile-card.list-card.horizontal-list-card ul li::before {
    width: 1em;
}

.vertical-list-card ul, .horizontal-list-card ul{
    font-size: 18px;
}

.vertical-list-card ul li{
    margin-bottom: 2%;
}

.horizontal-list-card ul li:not(:first-child){
    margin-left: 40px;
}

.horizontal-list-card{
    padding: 1% 0 1.5%;
}

.vertical-list-card ul li p{
    margin: 1.5% 0 1.5% 3.5%;
}

.languages-card figure{
    width: 28%;
    flex-basis: 28%;
}

.languages-card figure img{
    width: 100%;
}

.languages-card > div{
    width: 100%;
}

.languages-card > div > div{
    flex-basis: 33%;
}

.languages-card > div > div span{
    margin: 0 0 0 7.5px;
    font-size: 17px;
}

.profile-card > p{
    font-size: 16px;
    font-family: Montserrat;
}

.profile-general-information .container.profile-container, .profile-general-information .container.path-container{
    display: none;
    
}

.container.profile-container, .container.path-container{
    padding-bottom: 12.5%;
}

.container.profile-container.active, .container.path-container.active{
    display: block;
}

.path-search input{
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.5);
    padding: 1.25% 1.5% 1.25% 2%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}

.path-search{
    margin: 2.5% auto 2%;
    position: relative;
}

.path-search figure{
    position: absolute;
    top: 10px;
    left: 10px;
}

.path-buttons-container button{
    flex-basis: 30%;
    border: 1px solid rgba(0,0,0,0.75);
    border-radius: 3px;
    padding: 1.5% 0;
    background: #fff;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}

.path-buttons-container button.active{
    border: none;
    background: #F2C714;
}

.path-buttons-container{
    margin: 0 auto 3%;
}

.path-container{
    background: #F9F9F9;
}

.path-container .PuestoBlock .PuestoBlockContent{
    margin-left: 17.5px;
}

.path-container .PuestoBlock figure{
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 3px;
}

.path-container .PuestoBlock .VerMas, .path-container .PuestoBlock .afinidad{
    display: none;
}

.path-container .PuestoBlock h3, .path-container .PuestoBlock p:last-of-type{
    font-family: Montserrat;
}

.path-container .PuestoBlock p:first-of-type{
    font-size: 13.5px;
    margin-bottom: 5px;
}

.path-container .PuestoBlock p.caption-event{
    font-family: Helvetica, sans-serif;
    color: #999999;
    font-size: 13px;
    letter-spacing: 0.25px;
}

.path-container .PuestoBlock p{
    color: #3F3F3F;
    font-size: 13.75px;
    margin: 0.75% 0;
}

.path-container .PuestoBlock h3{
    font-size: 16px;
}

.path-container .PuestoBlock{
    padding: 1% 0 0;
}

.path-container .PuestoBlock span{
    font-weight: bold;
    font-size: 13.5px;
    letter-spacing: 0.75px;
}

.path-container .PuestoBlock span i{
    margin: 0 0.75% 0 0;
}

.jobs-events-container{
    background: #fff;
    padding: 1% 3.5%;
    margin: 0 auto;
}

.path-container .PuestoBlock hr{
    margin: 2% 0 0;
}

.card .profile-general-information > figure, .card .profile-general-information{
    margin: 0;
}

.card .profile-general-information{
    padding: 2% 0;
}

.edit-image > div{
    margin-left: 7.5%;
    font-family: Montserrat;
}

.card button{
    background: #F2C714;
    border-radius: 5px;
    padding: 6.25% 2.5%;
    border: none;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
}

.custom-file-upload {
    background: #F2C714;
    border-radius: 5px;
    padding: 6.25% 2.5%;
    border: none;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

.custom-file-upload input[type="file"] {
    display: none;
}

.card.edit-info p{
    margin: 0;
    font-family: Montserrat;
}

.card.edit-info i{
    font-size: 26px;
}

.card.edit-info > div > div{
    margin-left: 15px;
    flex: 1 1;
    margin-bottom: 18px;
}

.card.edit-info > div:last-child i{
    font-size: 22px;
}

.card.edit-info input, .edit-contact-info select, .edit-contact-info input{
    border: none;
    border-bottom: 1px solid black;
    padding: 1.25% 1.5%;
    width: 90%;
}

.card.edit-contact-info.map-container > div:first-of-type{
    height: 175px !important;
}

.edit-contact-info select, .edit-contact-info input{
    margin: 1% 0;
    width: 100%;
}

.selects-container{
    padding: 5% 0 0;
}

.edit-contact-info > div.selects-container div:last-child{
    padding: 5% 0 0;
}

.edit-contact-info > div.selects-container div:last-child p{
    padding: 0 3.5%;
    color: #8A8A8A;
    font-weight: bold;
}

.edit-contact-info p{
    font-weight: bold;
    font-size: 13px;
    color: #999999;
}

.submit-button{
    background: #F2C714;
    border-radius: 5px;
    padding: 2%;
    border: none;
    width: 50%;
    font-weight: bold;
    text-transform: uppercase;
    margin: 7.5% auto; 
    display: block;
}

.big-medal-container{
    width: 90%;
    margin: 0 auto 3.5%;
    background: #fff;
    border: 1px solid #F2C714;
    border-radius: 2px;
    padding: 3% 5%;
}

.big-medal-container h3{
    margin: 0 0 2.75%;
    font-size: 18px;
    font-weight: bold;
}

.big-medal-container p{
    margin: 0 0 2.75%;
    font-size: 12.75px;
}

.big-medal-container span{
    color: #8A8A8A;
    font-size: 11.5px;
}

.big-medal-container > div{
    margin-left: 22.5px;
}

.big-medal-container img{
    width: 6.75%;
}

.edit-profile-button a, .see-more-button a{
    color: #4997FC;
    font-weight: bold;
    cursor: pointer;
}

.edit-profile-button a:hover, .see-more-button a:hover{
    text-decoration: none;
    color: #F2C714;
}

.edit-profile-button, .see-more-button{
    text-align: right;
    padding: 1.5% 3%;
}

.see-detail-event{
    margin: 2.5% 0 0;
}

.edit-profile-button i{
    margin: 0 0.75%;
}

.profile-user-state select{
    border: none;
}

.profile-user-state{
    margin-bottom: 0.75%;
}

.block-distance{
    margin-left: 16px;
    display: block;
}

.add-language-button, .remove-lang-button {
    color: #4E9AFC !important;
    cursor: pointer;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    font-weight: bold !important;
}

.path-search, .path-buttons-container, .jobs-events-container{
    width: 82.5%;
}

.registroNuevoIdioma .form-subtitle{
    font-weight: bold;
    font-family: Montserrat;
    color: #000;
    font-size: 15px;
}

.course-title{
    margin: 1.5% 0;
}

.edit-contact-info input{
    padding: 0.75% 1.5%;
    margin: 0.75% 0;
}

@media only screen and (max-width: 812px) {

    .path-container .PuestoBlock span i{
        margin: 0 1.75% 0 0;
    }

    .path-search, .path-buttons-container, .jobs-events-container{
        width: 100%;
    }

    .container.medals-page-container, .container.path-container:not(.active){
        padding-top: 127.5px;
    }

    .profile-user-state{
        margin-bottom: 2.25%;
    }

    .profile-general-information{
        margin: 15% 0 0;
    }

    .profile-general-information > figure{
        width: 105px;
        height: 105px;
        margin: 0 auto;
        border: 1px solid rgba(0,0,0,0.75);
    }

    .card-portrait{
        width: 57.5px;
        height: 57.5px;
    }

    .profile-card p{
        margin: 0;
    }

    .path-container .PuestoBlock{
        padding: 2% 0;
    }

    .medal-image{
        max-width: 32.5px;
        margin-bottom: 7.5px;
    }

    .medal-content{
        margin-bottom: 5%;
        font-size: 12px;
        text-align: center;
        flex-basis: 31%;
        margin: 0 1.25%;
        padding: 1.75% 0;
    }

    .big-medal-container{
        width: 90%;
        margin: 0 auto 3.5%;
        background: #fff;
        border: 1px solid #F2C714;
        border-radius: 2px;
        padding: 6% 5%;
    }

    .big-medal-container img{
        width: 12.5%;
    }

    .card{
        padding: 4%;
        margin: 5% 0;
    }

    .card h5{
        margin: 0 0 4%;
        padding-bottom: 2%;
        font-size: 14px;
    }

    .vertical-list-card ul li{
        margin-bottom: 3%;
    }

    .horizontal-list-card {
        padding: 2.5% 0 1.5%;
    }

    .path-search {
        margin: 5% 0 3%;
    }

    .path-search input{
        padding: 2.25% 1.5% 2.25% 4%;
        width: 100%;
    }

    .path-buttons-container button{
        flex-basis: 32%;
        padding: 2% 0;
    }

    .container.path-container.active{
        padding: 0 2.75% 12.5%;
    }

    .edit-profile-button i{
        margin: 0 1.75%;
    }

    .edit-contact-info select, .edit-contact-info input{
        margin: 2.5% 0;
        width: 100%;
    }

    .submit-button{
        padding: 5% 2.5%;
        width: 70%;
    }

    .jobs-events-container{
        padding: 5% 4.5%;
    }

    .path-container .PuestoBlock p:first-of-type{
        font-size: 13.5px;
        margin-bottom: 7.5px;
    }

    .path-container .PuestoBlock p{
        font-size: 13.75px;
        margin: 2.25% 0 1.25%;
    }

    .path-container .PuestoBlock hr{
        margin: 7.5% 0 0;
    }
}
.container.help-container{
    padding-top: 0px;
    padding-bottom: 8%;
}

.container.help-container.sub-view{
    padding-top: 137.5px;
}

.help-container:not(.sub-view) > h4{
    margin: 7.5% 0 1.75%;
    font-size: 24px;
}

.help-container:not(.sub-view) > h4:last-of-type{
    margin: 5% 0 1.25%;
    font-size: 20px;
}

.help-container h4{
    font-size: 20px;
    font-weight: bold;
    margin: 2.25% 0;
}

.help-container > p{
    line-height: 1.5;
}

.link-tabs-container a{
    padding: 1.5% 2%;
    border: 1px solid rgba(0,0,0,0.4);
    border-radius: 4px;
    color: rgba(0,0,0,0.75);
    margin-bottom: 1.75%;
    font-size: 15px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.1);
}

.link-tabs-container a p{
    margin: 0;
}
.help-steps, .help-steps h5{
    font-size: 15px;
}

.help-steps h5{
    font-weight: bold;
    margin: 0 0 2%;
}

.step{
    margin: 1.25% 0;
    align-content: center;
    align-items: center;
}

.step figure img{
    width: 100%;
}

.step figure{
    flex-basis: 7.5%;
}

.help-steps{
    margin: 3% 0;
}

.step > div{
    margin-left: 5%;
}

.principal-button{
    width: 50%;
    background: #F2C714;
    color: #000;
    border-radius: 7px;
    border: none;
    padding: 2%;
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5% auto 10%;
    display: block;
    text-align: center;
}

.panel-default>.panel-heading{
    background-image: none !important;
    background-color: #FAFAFA !important;
    
}

.panel-group .panel{
    border-radius: 1px !important;
    border: none;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.1);
    margin-bottom: 4% !important;
}

.panel-title{
    font-size: 15px;
}

.panel-title a:hover{
    text-decoration: none;
}

.panel-title a{
    color: rgba(0,0,0,0.75) !important;
}

.warning-message p{
    margin-left: 2.5%;
    font-weight: bold;
    color: #7A7A7A;
    margin-bottom: 0;
}

.warning-message img{
    -webkit-filter: invert(45%);
            filter: invert(45%);
}

.warning-message{
    margin: 7.5% 0;
}

.report-job textarea{
    padding: 1.5% 2% 7.5%;
    width: 100%;
    border-radius: 3px;
    margin: 5% 0 0;
}

@media only screen and (max-width: 812px) {
    
    .container.help-container.sub-view{
        padding-top: 127.5px;
    }

    .help-container:not(.sub-view) > h4:first-child{
        margin: 10% 0 3%;
    }

    .principal-button{
        width: 85%;
        padding: 4.75%;
        margin: 0 auto 27.5%;
    }

    .help-container h4{
        margin: 4% 0;
    }

    .help-container.sub-view > h4:not(:first-child){
        margin: 6% 0;
    }

    .link-tabs-container a{
        padding: 3%;
        margin-bottom: 3.75%;
    }

    .help-container:not(.sub-view) > h4, .help-container:not(.sub-view) > h4:last-of-type{
        margin: 5.5% 0 2.5%;
        font-size: 18px;
    }

    .step {
        margin: 5% 0;
        align-content: flex-start;
        align-items: flex-start;
    }

    .help-steps {
        margin: 7.5% 0;
    }

    .step figure {
        flex-basis: 7.5%;
        min-width: 7.5%;
    }
}

@media only screen and (max-width: 420px) {
    .help-container:not(.sub-view) > h4:first-child{
        margin: 20% 0 7.5%;
    }

    .step figure {
        flex-basis: 18%;
        min-width: 18%;
    }

    .help-container:not(.sub-view) > h4, .help-container:not(.sub-view) > h4:last-of-type{
        margin: 7.5% 0 4.25%;
        font-size: 18px;
    }

    .container.help-container{
        padding-bottom: 20%;
    }
    
}
.container:not(.profile-container.active, .path-container.active){
    padding-top: 137.5px;
}

.container.medals-page-container{
    padding-top: 137.5px;
}

.container.messages-container.inside-conversation{
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
}

.container h4{
    font-weight: bold;
    margin-top: 0;
    font-size: 20px;
}

.conversation figure{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
}

.conversation > div {
    margin-left: 18px;
}

.conversation{
    padding: 2.25% 0 2%;
    border-bottom: 1px solid rgba(0,0,0,0.125);
    color: inherit;
    cursor: pointer;
}

.conversation > div > div{
    padding: 7.5px 0 3px;
}

.conversation > div > div h4{
    margin-left: 7.5px;
    margin-bottom: 0;
    font-size: 19px;
    font-weight: normal;
}

.message-text-preview{
    color: rgba(0,0,0,0.65);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
    margin: 0;
    padding-bottom: 1px;
    font-size: 14.5px;
}

.conversation span{
    font-size: 10.5px;
    color: rgba(0,0,0,1);
    font-family: Montserrat;
}

.conversations-container, .no-messages-container{
    height: auto;
    display: none;
}

.conversations-container.show{
    display: block;
    margin: 2.5% 0 0;
}

.no-messages-container.show{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 5% 0 0;
}

.no-messages-container.show > p{
    font-weight: bold;
    font-family: Montserrat;
    font-size: 20px;
    width: 65%;
    text-align: center;
}

.no-messages-container.show .warning-message{
    padding: 0 15px;
    font-size: 12.5px;
    margin: 4% auto 0;
}

.no-messages-container.show .warning-message p{
    margin-left: 5%;
}

.inside-conversation{
    background: #F7F7F7;
    min-height: 100vh;
    height: auto;
    overflow: auto;
    margin-bottom: 50px;
}


.inside-conversation .inside-message:first-child .conversation{
    padding: 0 0 2%;
}

.inside-conversation .conversations-container.show{
    margin: 0;
}

.inside-conversation .conversation span{
    font-size: 13px;
    display: block;
    margin-bottom: 0;
}

.inside-conversation .message-text-preview{
    text-overflow: unset;
    white-space: normal;
    overflow: auto;
    max-width: none;
    padding: 0 0 2%;
    border-bottom: 1px solid rgba(0,0,0,0.125);
}

.inside-conversation .conversation > div > div{
    padding: 0px 0 3px;
}

.inside-conversation .conversation{
    border-bottom: none;
}

.show-more-messages{
    font-weight: bold;
    padding-bottom: 3%;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    border-bottom: 1px solid rgba(0,0,0,0.125);
}

.show-more-messages span{
    color: #F2C714;
    font-size: 20px;
    margin-left: 10px;
}

.faj-message textarea{
    width: 100%;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.275);
    padding: 2.5% 2.5% 15%;
}

button:disabled,
button[disabled]{
    opacity: 0.5;
}

#file-input{
    display: none;
}

.text-field-message{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.text-field-message label{
    color: #F2C714;
    font-size: 18px;
    background: #fff;
    margin-bottom: 0;
    padding: 12px 10px 12px 10px;
    height: 50px;
}

.text-field-message label.disabled {
    color: #D3D3D3;
}

.text-field-message textarea{
    flex: 1 1;
    padding: 14.5px 0 14.5px 10px;
    border: none;
    height: 50px;
    font-size: 16px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3), 0 1px 2px rgba(0,0,0,0.3);
}

.text-field-message textarea::-webkit-input-placeholder { /* WebKit browsers */
    font-size: 13.75px; 
}

.text-field-message textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-size: 13.75px;
}

.text-field-message textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
     font-size: 13.75px;
}

.text-field-message textarea:-ms-input-placeholder { /* Internet Explorer 10+ */
    font-size: 13.75px;
} 

.text-field-message textarea:focus, .faj-message textarea:focus{
    outline: none;
}


@media only screen and (max-width: 812px) {
    .container:not(.profile-container.active, .path-container.active){
        padding-top: 127.5px;
    }

    .no-messages-container.show .warning-message{
        margin: 7.5% auto 5%;
    }

    .container.medals-page-container{
        padding-top: 127.5px;
    }

    .conversation{
        padding: 4.5% 0 2%;
    }

    .conversations-container.show{
        margin: 5% 0 15%;
    }

    .container h4{
        font-size: 18px;
    }

    .no-messages-container.show{
        padding: 15% 0 7.5%;
    }

    .container.messages-container.inside-conversation{
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .inside-conversation .message-text-preview{
        padding: 2% 0 3%;
    }

    .inside-conversation .conversations-container.show{
        padding: 0 4%;
    }

    .text-field-message label{
        flex-basis: 10%; 
    }
}
body, html {
    font-family: Helvetica, sans-serif;
    padding: 0;
    margin: 0;
}

h1 {
    font-family: Montserrat;
}
