.magia {
    background-color: green;
    color: yellow;
}

.container{
    padding-top: 137.5px;
}

.puestos {
    padding: 50px 15px 85px;
    width: 90%;
    margin: 0 auto;
}
.puestos h1 {
    padding-left: 10px;
    margin-left: 10px;
    border-left: solid 2px #F2C714;
    font-size: 21px;
    font-family: Helvetica;
    margin-top: 20px;
    display: block;
    margin-bottom: 15px;
}
.mensajeBlue {
    background-color: #4796FC;
    color: rgba(255,255,255,0.9);
    font-size: 12px;
    font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.2), 0 1px 2px rgba(0,0,0,0.3);
    margin-bottom: 5px;
}
.puestos .mensajeBlue a,
.puestos .mensajeBlue a:active,
.puestos .mensajeBlue a:hover,
.puestos .mensajeBlue a:focus{
    outline: none;
    text-decoration: none;
    cursor: pointer;
}
.puestos .mensajeBlue a,
.puestos .mensajeBlue a:active,
.puestos .mensajeBlue a:hover,
.puestos .mensajeBlue a:focus {
    color: #fff;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

.puestos .mensajeBlue i {
    cursor: pointer;
}

.mensajeBlue span:last-child{
    cursor: pointer;
}

.puestos .filterBox {
    width: 100%;
    border: solid 1px gray;
    padding: 1.25% 1.5% 1.25% 2.5%;
    margin-top: 15px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}
.puestos .searchControl {
    position: relative;
}
.puestos .filtroIcon {
    position: absolute;
    right: 15px;
    top: 30px;
    cursor: pointer;
}
.listadoFiltros{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
}
.listadoFiltros .filtroTab{
    text-align: center;
    color: #000000;
    width: 100%;
    border-bottom: 1px solid #D8D8D8;
    font-family: Montserrat;
    font-size: 15px;
    cursor: pointer;
    padding: 1% 0;
}
.listadoFiltros .filtroTab.active{
    font-weight: bold;
    border-bottom: 7px solid #F2C714;
}
.puestos .legend {
    margin: 15px 0 30px;
    display: block;
    font-size: 16px;
}
/* Puesto item */
.PuestoBlockContent{
    margin-left: 10px;
    width: 100%;
}
.PuestoBlockContent ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    float: right;
    width: 100px;
    list-style: none;
}
.PuestoBlockContent ul li{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: none;
    border: solid 1px #F2C714;
}
.PuestoBlockContent ul li.active{
    background-color: #F2C714 ;
    border: solid 1px #F2C714;
}
.PuestoBlock{
    display: flex;
    position: relative;
    padding: 1.5% 1.25% 0.75%;
    border-bottom: 1px solid rgba(0,0,0,0.125);
}
.PuestoBlock:hover{
    background: #1A1A1A;
    color: #fff;
}
.PuestoBlock:hover h3, .PuestoBlock:hover .VerMas i{
    color: #fff;
}
.PuestoBlock >img {
    height: 54px;
    margin-right: 3px;
}

.PuestoBlock figure{
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1), 0 2px 2px rgba(0,0,0,0.2);
}
.PuestoBlock h3 {
    margin: 0 0 5px;
    padding: 0;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: bold;
    color: #000;
}
.PuestoBlock p {
    padding: 0;
    margin: 0 0 5px;
    font-size: 14px;
    font-family: Montserrat;
}
.PuestoBlock span {
    font-size: 14px;
    color: #999999;
    font-weight: bold;
    margin: 0 0 5px;
    display: block;
}
.PuestoBlock p:not(.afinidad) span {
    display: block;
    margin: 4px 2.5px;
}
.PuestoBlock span.AfinidadIndicador{
    margin: 0;
}
.PuestoBlock span i{
    margin: 0 5px 0 1.5px;
}
.PuestoBlock .afinidad {
    font-size: 13.75px;
    font-family: Montserrat;
    width: 26%;
}
.PuestoBlock hr {
    margin-top: 5px;
    margin-bottom: 5px;
}
.AfinidadIndicador {
    margin-left: 5px;
    top: 1px;
    position: relative;
}
.AfinidadIndicador span {
    border: solid 1px #F2C714;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 7px;
    margin-bottom: 0;
    display: inline-block;
}
.AfinidadIndicador span:first-child {
    margin-left: 10px;
}
.AfinidadIndicador span.active {
    background-color: #F2C714;
}

.blockLink,
.blockLink:hover {
    color: black;
    text-decoration: none;
}

.VerMas {
    position: absolute;
    right: 3px;
    top: 50px;
    width: 55px;
    font-weight: bold;
    color: #4796FC;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.PuestoBlock p .VerMas span{
    display: none;
    margin: 0;
    color: #4796FC;
}

.VerMas i{
    font-size: 18px;
    color: #000;
    display: inline;
    margin: 0;
}

/**/

.flex{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
}

.nowrap{
    flex-flow: row nowrap; 
}

.flex-column{
    flex-flow: column wrap;
}

.flex-center{
    align-content: center;
    align-items: center
}

.justify-center{
    justify-content: center;
}

.justify-start{
    justify-content: flex-start;
}

.job-detail figure{
    width: 250px;
    height: 180px;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.job-detail figure img{
    width: 100%;
}

.job-detail > div{
    flex-basis: 75.75%;
}

.job-detail > div h3{
    font-size: 38px;
    font-weight: bold;
}

.job-detail > div h3, .job-detail p{
    margin: 5px 0 10px 0;
}

.company-name, .mensajeBlue{
    font-family: Montserrat;
}

.company-name{
    font-size: 22px;
}

.job-detail p.location{
    font-size: 18px;
    margin: 5px 0;
}

.location span{
    margin-left: 10px;
    flex-basis: 90%;
    word-wrap: break-word;
    color: #999999;
    font-weight: bold;
}

.job-detail .distancia {
    font-family: Helvetica;
    font-size: 15px;
    color: #999999;
}
.job-detail .distancia{
    margin-left: 20px;
    color: #999999;
    font-weight: bold;
}

.mensajeBlue.large-message{
    margin: 0 0 2.25%;
}

.job-status p, .afinidad{
    font-size: 17px;
}

.job-status p:not(:nth-child(3)){
    font-weight: bold;
    font-size: 18px;
}

.job-info-icons div{
    flex-basis: 50%;
}

.job-info-icons{
    padding: 4.5% 0;
}

.job-info-icons figure{
    margin-bottom: 2.5%;
}

.job-info-icons p{
    margin-bottom: 0.5%;
}

.job-info-icons div.info-with-border{
    border-right: 3px solid #F2C714;
}

.job-info-icons div{
    min-height: 82px;
}

.job-status p.requirement{
    font-weight: normal;
}

.job-status p span{
    margin: 0 7.5px 0 5px;
}

.afinidad{
    margin: 1.5% 0 1%;
}

.job-status:nth-child(2) p:first-child{
    margin: 1.5% 0;
}

.job-detail{
    margin: 0 0 2.25%;
}

/**/

.detalleContainer {
    min-height: 100vh;
}

.job-status p span{
    color: #F2C714;
    font-weight: bold;
}

.affinities-container{
    list-style-type: none;
    padding-left: 0px;
    justify-content: flex-start;
}

.affinities-container li:not(:last-child){
    padding: 0.65% 1.25%;
    background: #1A1A1A;
    color: rgba(255,255,255,0.9);
    border-radius: 3px;
    text-align: center;
    font-size: 13.5px;
}

.affinities-container li{
    margin: 0.5% 1.5% 1% 0;
}

.affinities-container li:last-child{
    color: #4897FC;
    font-weight: bold;
    cursor: pointer;
    visibility: hidden;
}

.affinities-container li:last-child.active{
    visibility: visible;
}

.job-actions, .day-event-container{
    background: #eeeeee;
    padding: 5.5% 0;
    margin: 2.5% auto;
}

.job-actions button:first-child{
    background: transparent;
    border: none;
    color: #000;
    font-weight: bold;
    order: 3;
}

.job-actions button:first-child span{
    border-bottom: 1px solid #000;
}

.job-actions button:first-child img{
    padding-left: 10px;
}

.job-actions button:last-child, .day-event-container button{
    width: 50%;
    background: #F2C714;
    color: #000;
    border-radius: 6px;
    border: none;
    padding: 2%;
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
}

.day-event-container input{
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.25);
    padding: 1%;
    width: 50%;
    margin-bottom: 1%;
}

a:hover{
    text-decoration: none !important;
}

.mensajeBlue:hover{
    color: #fff;
}

.near-me{
    margin-bottom: 30px;
}

.no-jobs{
    text-align: center;
    font-weight: bold;
    margin: 50px 0;
}

.PuestoBlock p:not(.afinidad) span.job-span-location{
    margin: 4px 0 2.5px;
    width: 100%;
}

@media only screen and (max-width: 812px) {

    .job-detail .distancia {
        font-size: 14px;
        margin-left: 22.5px;
    }

    .job-actions, .day-event-container{
        padding: 5% 0 7.5%;
        margin: 2.5% auto;
    }

    .day-event-container{
        padding: 5% 0 17.5%
    }

    .PuestoBlock figure{
        width: 50px;
        min-width: 50px;
        height: 50px;
    }

    .container{
        padding-top: 127.5px;
    }

    .detalleContainer div:nth-child(2){
        margin-bottom: 5px;
    }

    .back-image{
        width: 1.75%;
    }

    .job-detail{
        padding: 2% 3.5%;
        margin: 0;
    }

    .job-detail figure{
        width: 100px;
        height: 100px;
    }

    .job-detail figure img{
        width: 100%;
    }

    .job-detail > div{
        flex-basis: 69%;
    }

    .job-detail > div h3, .job-detail p{
        margin: 0 0 7.5px 0;
    }

    .job-detail > div h3{
        font-size: 22px;
    }

    .location, .company-name{
        font-size: 14px;
    }

    .location span{
        margin-left: 7.5px;
    }

    .job-detail p.location{
        margin: 0 0 2.5px 0;
    }

    .mensajeBlue.large-message{
        width: 92.5%;
        margin: 5.5% auto;
        padding: 7.5px 10px;
    }

    .mensajeBlue.large-message span:first-of-type{
        flex-basis: 82.5%;
    }

    .mensajeBlue.large-message span:last-of-type{
        align-self: flex-start;
    }

    .job-information{
        padding: 0 5% 5%;
    }

    .job-info-icons figure{
        margin-bottom: 5%;
    }

    .job-status p, .afinidad{
        font-size: 14px;
    }

    .affinities-container li:not(:last-child){
        padding: 2.5% 3%;
    }

    .affinities-container li{
        margin: 0;
    }

    /* .affinities-container li:nth-child(3n+2), .affinities-container li:last-child{
        margin: 1.5% 3%;
    } */

    .affinities-container li{
        margin: 1.5% 3% 1.5% 0;
    }

    .job-actions{
        padding: 7.5% 0 20%;
    }

    .job-actions button:last-child, .day-event-container button{
        width: 75%;
        padding: 4.25%;
    }

    .day-event-container input{
        width: 75%;
        padding: 2.5%;
        text-align: center;
        margin-bottom: 2.5%;
    }

    .job-actions button:first-child{
        margin-bottom: 5%;
    }

    .afinidad{
        margin: 5% 0 2.5%;
    }

    .job-status:nth-child(2) p:first-child{
        margin: 5% 0;
    }

    .job-info-icons{
        padding: 6.5% 0;
    }

    .job-status p:not(:nth-child(3)){
        font-size: 16px;
    }

    .listadoFiltros .filtroTab{
        padding: 1.75% 0;
    }

    .listadoFiltros{
        margin-top: 20px;
    }

    .puestos {
        padding: 50px 15px 75px;
        width: 100%;
    }

    .puestos .filterBox {
        padding: 3% 1.5% 3% 4%;
        margin-top: 15px;
    }

    .puestos .filtroIcon {
        right: 10px;
        top: 25px;
    }

    .PuestoBlock{
        padding: 3.5% 2.5% 1.5%;
    }

    .PuestoBlock h3{
        font-size: 15px;
        margin: 0 0 7.5px;
    }

    .PuestoBlock p {
        font-size: 14px;
        margin: 0 0 4px;
    }

    .PuestoBlock span {
        font-size: 13px;
        margin: 0 0 4px;
    }

    .PuestoBlock p:not(.afinidad) span {
        display: block;
        margin: 4px 0 0;
    }

    .PuestoBlock .afinidad {
        font-size: 13px;
        width: 100%;
    }

    .puestos .legend {
        font-size: 15px;
    }

    .PuestoBlock p:not(.afinidad) .VerMas{
        position: relative;
        top: auto;
        right: auto;
    }

    .PuestoBlock p:not(.afinidad) .VerMas span{
        margin: 0;
    }

    .PuestoBlock p:not(.afinidad) .VerMas i{
        font-size: 16px;
    }

    .PuestoBlock p:not(.afinidad) span.job-span-location{
        margin: 5px 0 2.5px;
    }
}

@media only screen and (max-width: 420px) {
    .job-detail > div{
        flex-basis: 65%;
    }

    .job-detail p.location{
        width: 100%;
    }

    .day-event-container{
        padding: 5% 0 22.5%
    }
}
