.container {
    padding-top: 175px;
    text-align: center;
    padding-bottom: 18%;
}

.container h1 {
    margin: 28px 0 16px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}

.container p {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #3F3F3F;
}

.box {
    border: 1px solid #F2C714;
    padding: 20px 7px 8px 11px;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 20px;
}

.content {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.content img {
    height: 50px;
    width: 50px;
    margin-right: 9px;
}

.content h2 {
    font-size: 15px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 18px;
    margin: 0;
}

.content p {
    color: #3F3F3F;
    font-size: 12px;
    line-height: 17px;
    margin: 5px 0 4px;
}

.content span {
    color: #626262;
    font-size: 12px;
    line-height: 17px;
}

.content .location {
    height: 14px;
    width: 10px;
    display: inline-block;
    background-image: url('../../assets/images/ping.png');
    margin-right: 9px;
}

.box a {
    color: #4796FC;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    float: right;
}

@media only screen and (max-width: 812px) {
    .container {
        padding-bottom: 22.5%;
    }
}

@media only screen and (max-width: 420px) {
    .container {
        padding-bottom: 50%;
    }
}