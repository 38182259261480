@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css";

.Bienvenida {
    background: #000;
    color: #fff;
    width: 100%;
    display: block;
    height: 100vh;
}
.Bienvenida .slider h1,
.Bienvenida .slider p,
.Bienvenida .slider img{
    text-align: center;
    margin: 45px 0;
    color: #fff;
}
.Bienvenida .slider h1{
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    margin: 45px 0 27.5px;
}
.Bienvenida .slider p{
    font-size: 13px;
    font-family: Montserrat;
    max-width: 325px;
    margin: 0 auto;
    line-height: 1.5;
}
.Bienvenida .slider img{
    display: block;
    margin: 0 auto;
    margin-top: 35px;
}
.Bienvenida .slick-dots li button:before{
    padding: 35px 0;
}
.Bienvenida .slick-dots li button:before{
    opacity: 1;
    color: white;
    font-size: 12px;
}
.Bienvenida .slick-dots li.slick-active button:before{
    opacity: 1;
    color: #F2C714;
}
.Bienvenida .comenzar {
    background-color: #F2C714;
    color: #1A1A1A;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 15px;
    padding: 15px 0;
    width: 275px;
    display: block;
    margin: 90px auto 0;
    border: none;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
}

.slick-next, .slick-prev{
    display: none !important;
}

@media only screen and (max-width: 823px) {
    .Bienvenida {
        padding: 0 0 12.5%;
        height: auto;
    }
}

@media only screen and (max-width: 420px) {
    .Bienvenida {
        padding: 0 0 27.5%;
    }

    .Bienvenida .slider h1{
        padding: 0 10px;
    }

    .Bienvenida .slick-dots li button:before{
        padding: 27.5px 0 35px;
    }
}

@media only screen 
    and (max-width : 411px) 
    and (max-height : 823px) {
        .Bienvenida {
            padding: 17.5% 0 35%;
        }
}

@media only screen 
    and (max-width : 411px) 
    and (max-height : 740px) {
        .Bienvenida {
            padding: 5% 0 27.5%;
        }
}

@media only screen and (max-width: 375px) {
    .Bienvenida {
        padding: 0 0 22.5%;
    }
}

@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) {
        .Bienvenida {
            padding: 15% 0 37.5%;
        }
     }


