.filtro {
    background-color: #000;
    width: 100vw;
    height: 100vh;
}
.filtro h1 {
    color: #F2C714;
    font-size: 24px;
    font-weight: bold;
    font-family: Montserrat;
    padding: 0;
    margin: 0;
    display: block;
}
.filtro .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}