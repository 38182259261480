.registro .content {
    padding: 30px 15px 0 15px;
}

.registro .content h1 {
    color: #F2C714;
    font-size: 20px;
    font-weight: bold;
    font-family: Montserrat;
    margin-bottom: 20px;
}

.registro .content h1.success-title{
    color: #000;
}

.registro .content h4 {
    color: #1A1A1A;
    font-size: 17px;
    font-weight: bold;
    font-family: Helvetica;
    margin-top: 15px;
}

.registro .content a:not(.btn){
    color: #4796FC;
    font-weight: bold;
}

.registro .content h5 {
    color: #1A1A1A;
    font-size: 15px;
    font-weight: bold;
}
.registro .content p {
    color: #999999;
    font-size: 13px;
    font-weight: bold;
    font-family: Helvetica;
}
.registro input[type=text],
.registro input[type=number],
.registro input[type=password],
.registro input[type=email]
{
    border: none;   
    border-bottom: solid 1px black;
    width: 100%;
    font-size: 16px;
    font-family: Helvetica;
    color: #3F3F3F;
    margin-bottom: 17.5px;
    outline: none;
    padding: 1% 0.5% 0.5%;
}
.registro input[type=text]::placeholder,
.registro input[type=password]::placeholder,
.registro input[type=email]::placeholder{
    font-size: 16px;
    font-family: Helvetica;
    color: #3F3F3F;
}
.registro .buttons a {
    background-color: white;
    color: #000;
    width: 47.5%;
    font-family: Montserrat;
    font-size: 14px;
    padding: 17.5px;
    border-radius: 4px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
}
.registro .buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 35px;
    margin-top: 35px;
}
.registro .btn-default{
    background: #fff;
}
.registro .btn-primary{
    background: #F2C714 !important;
    width: 47.5%;
    margin-right: 3px;
    color: #000000;
    border: none;
    padding: 18px;
    font-weight: bold;
    border-radius: 4px;
    font-size: 14px;
    font-family: Montserrat; 
}
.registro .btn-primary.white{
    background: #ffffff !important;
    border: 1px solid #000000;
    color: #000;
}
.registro .icon {
    float: right;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 65px;
}
.registro select {
    border: none;
    background-color: transparent;
    border-bottom: solid 1px #3F3F3F;
    width: 100%;
    border-radius: 0;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAELY03+AAAABGdBTUEAALGPC/xhBQAAAE9JREFUCB1j+HxcrOHTcZH/DGCCAQhAIiCaES4C4sEBTANMFkMZIwNTI1g1SAUIwwxEMQLGYQQxQCr+M/yrhwki0yDjeC1fNSCLgTXgMh4AlLszsG1mvGAAAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    background-position: right 12.5px;
    border-right: solid 10px transparent;
    margin-bottom: 17.5px;
    padding: 1% 0% 0.5%;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 16px;
}
.registro select::-ms-expand {
    display: none;
}
.registroNuevoIdioma {
    margin-top: 15px;
}
.habilidadCategoria span.boton{
    float: right;
    font-weight: 16px;
    font-weight: bold;
    cursor: pointer;
}
.habilidadCategoria hr {
    padding: 0;
    margin: 7.5px 0 0;
}
.habilidadLista {
    display: none;
    padding: 1% 0 0;
}
.habilidadLista > span {
    padding: 9.5px 10px;
    margin: 5px;
    border: solid 1px #000;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    font-weight: normal;
}
.registro.final .imagenListo{
    text-align: center;
    margin: 35px auto;
    display: block;
}
.registro.final .content h1 {
    color: #1A1A1A;
    text-align: center;
}
.registro.final .content p {
    text-align: center;
    color: #3F3F3F;
}
.registro .error {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: red;
}
.registro.paso1,
.registro.paso2,
.registro.paso3,
.registro.paso4,
.registro.paso5,
.registro.paso6{
    /* display: none; */
}
.registro.show {
    display: block;
}

.registro .btn.agregar{
    float: right;
}
.registro .nuevaHabilidad,
.registro .nuevaHabilidad:hover {
    color: #4796FC;
    font-family: Helvetica;
    font-size: 12px;
    text-decoration: none;
}
.registro .agregarHabilidadContainer{
    padding: 25px 0;
    display: none;
}
.registro.paso5 label {
    margin-right: 15px;
}
.registro.paso6 .content {
    text-align: center;
}
.registro .habilidadSeleccionada {
    background: #000;
    color: #fff;
    border-radius: 4px;
    padding: 10px;
    width: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: inline-block;
    font-family: Montserrat;
}
.registro .habilidadesSeleccionadas{
    padding: 15px;
    clear: both;
    width: 100%;
    display: block;
}

.habilidadCategoria{
    padding: 1.5% 0;
    font-weight: bold;
    font-size: 15.5px;
}

.registro .content label{
    font-weight: normal;
}

.flex-labels, .flex-labels label, .registro.paso5 .content label{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.flex-labels label{
    margin-right: 15px;
    width: 50px;
}

.flex-labels label input, .registro.paso5 .content label input{
    margin: 0 5px 0 0;
}

.flex-labels label input{
    width: 25px;
    margin: 0;
}

.flex-labels{
    margin: 15px 0;
}

.registro.paso5 .content label{
    font-size: 15px;
}

select:focus{
    outline: none;
}

.registro .content p.form-subtitle{
    font-weight: bold;
    font-family: Montserrat;
    color: #000;
    font-size: 15px;
}

.add-abilities-container{
    display: none;
    margin-bottom: 55px;
}

.add-ability-option{
    margin: 10px 0 0;
    display: block;
}

.add-abilities-container input{
    border: none;   
    border-bottom: solid 1px black;
    width: 43.5%;
    font-size: 16px;
    font-family: Helvetica;
    color: #3F3F3F;
    outline: none;
    padding: 1% 0.5% 0.5%;
}

.add-abilities-container button{
    background: #4796FC;
    font-weight: bold;
    border-radius: 2px;
    border: none;
    padding: 0.75%;
    width: 10%;
    margin-left: 10px;
    color: #fff;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.2);
}

.select-component input {
    margin-bottom: 0 !important;
}


@media only screen and (max-width: 812px) {
    .registro input[type=text], .registro input[type=password], .registro input[type=email]{
        padding: 1.75% 1%;
        margin-bottom: 22.5px;
    }

    .registro select{
        padding: 1.75% 0;
    }

    .registro .content h1 {
        font-size: 24px;
    }

    .registro .content p {
        font-size: 13.5px;
    }

    .habilidadCategoria hr{
        margin: 12.5px 0 10px;
    }

    .habilidadLista > span {
        white-space: normal;
    }

    .registro .content h4{
        margin-bottom: 17.5px;
    }
}