.forgot-container{
    height: 100vh;
    background: #1A1A1A;
}

.forgot-container input{
    background-color: transparent;
    border: solid 1px #979797;
    border-radius: 4px;
    padding: 15px 0 15px 47.5px;
    margin-bottom: 20px;
    height: auto;
    width: 100%;
    color: #fff;
    font-family: Montserrat;
    font-weight: bold;
}

.forgot-container form{
    width: 35%;
}

.forgot-container input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
}

.forgot-container input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}

.forgot-container input::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
}

.forgot-buttons button{
    width: 47.5%;
    color: #000;
    border-radius: 6px;
    border: none;
    padding: 3.75%;
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
}

.forgot-buttons button:first-child{
    background: #FFF;
}

.forgot-buttons button:last-child{
    background: #F2C714;
}

.forgot-container p{
    width: 100%;
    text-align: center;
    margin: 2.5% 0;
    font-family: Montserrat;
    font-weight: bold;
    color: #fff;
    opacity: 0.8;
}

.forgot-container h1{
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    margin: 0;
}

@media only screen and (max-width: 812px) {

    .forgot-container{
        padding: 15px;
    }

    .forgot-container h1{
        font-size: 28px;
    }

    .forgot-container br{
        display: none;
    }

    .forgot-container form{
        width: 100%;
    }
}