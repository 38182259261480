.filtroOpcion {
    background-color: #fff;
    margin: 0 35px 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 5%;
    text-decoration: none;
}
.filtroOpcion:active,
.filtroOpcion:hover,
.filtroOpcion:focus {
    text-decoration: none;
}
.filtroOpcion p {
    color: #F2C714;
    font-size: 15px;
    font-family: Montserrat;
    font-weight: bold;
    padding: 10px;
}