.container.help-container{
    padding-top: 0px;
    padding-bottom: 8%;
}

.container.help-container.sub-view{
    padding-top: 137.5px;
}

.help-container:not(.sub-view) > h4{
    margin: 7.5% 0 1.75%;
    font-size: 24px;
}

.help-container:not(.sub-view) > h4:last-of-type{
    margin: 5% 0 1.25%;
    font-size: 20px;
}

.help-container h4{
    font-size: 20px;
    font-weight: bold;
    margin: 2.25% 0;
}

.help-container > p{
    line-height: 1.5;
}

.link-tabs-container a{
    padding: 1.5% 2%;
    border: 1px solid rgba(0,0,0,0.4);
    border-radius: 4px;
    color: rgba(0,0,0,0.75);
    margin-bottom: 1.75%;
    font-size: 15px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.1);
}

.link-tabs-container a p{
    margin: 0;
}
.help-steps, .help-steps h5{
    font-size: 15px;
}

.help-steps h5{
    font-weight: bold;
    margin: 0 0 2%;
}

.step{
    margin: 1.25% 0;
    align-content: center;
    align-items: center;
}

.step figure img{
    width: 100%;
}

.step figure{
    flex-basis: 7.5%;
}

.help-steps{
    margin: 3% 0;
}

.step > div{
    margin-left: 5%;
}

.principal-button{
    width: 50%;
    background: #F2C714;
    color: #000;
    border-radius: 7px;
    border: none;
    padding: 2%;
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5% auto 10%;
    display: block;
    text-align: center;
}

.panel-default>.panel-heading{
    background-image: none !important;
    background-color: #FAFAFA !important;
    
}

.panel-group .panel{
    border-radius: 1px !important;
    border: none;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.1);
    margin-bottom: 4% !important;
}

.panel-title{
    font-size: 15px;
}

.panel-title a:hover{
    text-decoration: none;
}

.panel-title a{
    color: rgba(0,0,0,0.75) !important;
}

.warning-message p{
    margin-left: 2.5%;
    font-weight: bold;
    color: #7A7A7A;
    margin-bottom: 0;
}

.warning-message img{
    filter: invert(45%);
}

.warning-message{
    margin: 7.5% 0;
}

.report-job textarea{
    padding: 1.5% 2% 7.5%;
    width: 100%;
    border-radius: 3px;
    margin: 5% 0 0;
}

@media only screen and (max-width: 812px) {
    
    .container.help-container.sub-view{
        padding-top: 127.5px;
    }

    .help-container:not(.sub-view) > h4:first-child{
        margin: 10% 0 3%;
    }

    .principal-button{
        width: 85%;
        padding: 4.75%;
        margin: 0 auto 27.5%;
    }

    .help-container h4{
        margin: 4% 0;
    }

    .help-container.sub-view > h4:not(:first-child){
        margin: 6% 0;
    }

    .link-tabs-container a{
        padding: 3%;
        margin-bottom: 3.75%;
    }

    .help-container:not(.sub-view) > h4, .help-container:not(.sub-view) > h4:last-of-type{
        margin: 5.5% 0 2.5%;
        font-size: 18px;
    }

    .step {
        margin: 5% 0;
        align-content: flex-start;
        align-items: flex-start;
    }

    .help-steps {
        margin: 7.5% 0;
    }

    .step figure {
        flex-basis: 7.5%;
        min-width: 7.5%;
    }
}

@media only screen and (max-width: 420px) {
    .help-container:not(.sub-view) > h4:first-child{
        margin: 20% 0 7.5%;
    }

    .step figure {
        flex-basis: 18%;
        min-width: 18%;
    }

    .help-container:not(.sub-view) > h4, .help-container:not(.sub-view) > h4:last-of-type{
        margin: 7.5% 0 4.25%;
        font-size: 18px;
    }

    .container.help-container{
        padding-bottom: 20%;
    }
    
}