.magia {
    background-color: green;
    color: yellow;
}

.eventos:not(.detalle) {
    padding: 50px 15px 75px;
    width: 90%;
    margin: 0 auto;
}
.eventos h1 {
    padding-left: 10px;
    margin-left: 10px;
    border-left: solid 2px #F2C714;
    font-size: 21px;
    font-family: Helvetica;
    margin-top: 20px;
    display: block;
    margin-bottom: 15px;
}
.eventos .mensajeBlue {
    background-color: #4796FC;
    color: #fff;
    font-size: 12px;
    font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 5px;
    font-family: Montserrat;
}
.eventos .mensajeBlue a,
.eventos .mensajeBlue a:active,
.eventos .mensajeBlue a:hover,
.eventos .mensajeBlue a:focus{
    outline: none;
    text-decoration: none;
    cursor: pointer;
}
.eventos .mensajeBlue a,
.eventos .mensajeBlue a:active,
.eventos .mensajeBlue a:hover,
.eventos .mensajeBlue a:focus {
    color: #fff;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

.eventos .mensajeBlue i {
    cursor: pointer;
}

.eventos .filterBox {
    width: 100%;
    border: solid 1px gray;
    padding: 1.25% 1.5% 1.25% 2.5%;
    margin-top: 15px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}
.eventos .searchControl {
    position: relative;
}
.eventos .filtroIcon {
    position: absolute;
    right: 15px;
    top: 30px;
    cursor: pointer;
}
/* .eventos .listadoFiltros{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;

}
.eventos .listadoFiltros a{
    text-align: center;
    color: #000000;
    width: 100%;
    border-bottom: 1px solid gray;
    font-family: Montserrat;
    font-size: 14px;
}
.eventos .listadoFiltros a.active{
    font-weight: bold;
    border-bottom: 3px solid #F2C714;
} */
.eventos .listadoFiltros{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 25px;
}
.eventos .listadoFiltros .filtroTab{
    text-align: center;
    color: #000000;
    width: 100%;
    border-bottom: 1px solid #D8D8D8;
    font-family: Montserrat;
    font-size: 15px;
    cursor: pointer;
}
.eventos .listadoFiltros .filtroTab.active{
    /* font-weight: bold; */
    border-bottom: 7px solid #F2C714;
}
.eventos .legend {
    margin: 15px 0 30px;
    display: block;
    font-size: 16px;
}
/* Evento item */
.EventoBlockContent{
    margin-left: 10px;
    width: 100%;
}
.EventoBlockContent ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    float: right;
    width: 100px;
    list-style: none;
}
.EventoBlockContent ul li{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: none;
    border: solid 1px #F2C714;
}
.EventoBlockContent ul li.active{
    background-color: #F2C714 ;
    border: solid 1px #F2C714;
}
.EventoBlock{
    display: flex;
    position: relative;
    padding: 1.5% 1.25% 0.75%;
    border-bottom: 1px solid rgba(0,0,0,0.125);
}
.EventoBlock:hover{
    background: #1A1A1A;
    color: #fff;
}
.EventoBlock:hover h3, .EventoBlock:hover .VerMas i{
    color: #fff;
}
.EventoBlock >img {
    height: 54px;
    margin-right: 3px;
}
.EventoBlock figure{
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1), 0 2px 2px rgba(0,0,0,0.2);
}
.EventoBlock h3 {
    margin: 0 0 5px;
    padding: 0;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: bold;
    color: #000;
}
.EventoBlock p {
    padding: 0;
    margin: 0 0 5px;
    font-size: 14px;
    font-family: Montserrat;
}
.EventoBlock span {
    font-size: 14px;
    color: #999999;
    font-weight: bold;
    margin: 0 0 5px;
    display: block;
}
.EventoBlock p:not(.afinidad) span {
    display: block;
    margin: 4px 2.5px;
}
.EventoBlock span.AfinidadIndicador{
    margin: 0;
}
.EventoBlock span i{
    margin: 0 5px 0 1.5px;
}
.EventoBlock .afinidad {
    font-size: 13.75px;
    font-family: Montserrat;
    width: 26%;
}
.EventoBlock hr {
    margin-top: 5px;
    margin-bottom: 5px;
}
.eventos.detalle {
    padding-top: 0;
}
.eventos.detalle .header>img{
    float: left;
    margin: 15px;
}
.eventos.detalle h1{
    border: none;
    margin-top: 15px;
    float: left;
    font-family: Helvetica;
    font-size: 20px;
}
.eventos.detalle .empresa {
    font-family: Montserrat;
    font-size: 12px;
    color: #3F3F3F;
}
.eventos.detalle .direccion,
.eventos.detalle .distancia {
    font-family: Helvetica;
    font-size: 15px;
    color: #999999;
}
.eventos.detalle .distancia{
    margin-left: 20px;
    color: #999999;
    font-weight: bold;
}
.eventos.detalle .content {
    display: block;
    clear: both;
}
.eventos.detalle .content img {
    margin: 0 10px;
}
.medallaContent {
    color: #999999;
    padding-left: 42.5px;
    padding-right: 15px;
    font-size: 14px;
    font-family: Helvetica;
    margin: 0.5% 0 1.5%;
    font-weight: bold;
}
.eventos.detalle .content :not(.medallaTitle) p {
    color: #3f3f3f;
    font-size: 14px;
    font-family: Helvetica;
    font-weight: lighter;
}
.medallaTitle p{
    margin: 0;
}
.medallaTitle p, .eventos.detalle .content p.subTitulo{
    font-weight: bold;
    font-size: 16px;
}
.eventos.detalle .content p.subTitulo{
    margin: 2% 0 0.25%;
}
.eventos.detalle .content p.afinidad{
    font-size: 15px;
    font-weight: bold;
    /* width: 27.5%; */
}
.eventos.detalle .descripcion .subTitulo {
    font-family: Helvetica;
    font-size: 14px;
    color: #1a1a1a;

}
.eventos.detalle .descripcion .afinidad {
    font-size: 12px;
    font-family: Montserrat;
    color: #3f3f3f;
}
.AfinidadIndicador {
    margin-left: 5px;
    top: 1px;
    position: relative;
}
.AfinidadIndicador span {
    border: solid 1px #F2C714;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 7px;
    margin-bottom: 0;
    display: inline-block;
}
.AfinidadIndicador span:first-child {
    margin-left: 10px;
}
.AfinidadIndicador span.active {
    background-color: #F2C714;
}

.blockLink,
.blockLink:hover {
    color: black;
    text-decoration: none;
}

.EventoBlock .VerMas {
    position: absolute;
    right: 3px;
    top: 60px;
    width: 55px;
    font-weight: bold;
    color: #4796FC;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.EventoBlock p .VerMas span{
    display: none;
    margin: 0;
    color: #4796FC;
}

.VerMas i{
    font-size: 18px;
    color: #000;
    display: inline;
    margin: 0;
}
.nav-control {
    background-color: #f9f9f9;
    padding: 15px;
}
.eventos.detalle .habilidades span {
    background-color: #000;
    color: white;
    padding: 10px;
    font-size: 12px;
    font-weight: lighter;
    font-family: Helvetica;
    margin-right: 15px;
}
.eventos.detalle .habilidades .verMas,
.eventos.detalle .habilidades .verMas:active,
.eventos.detalle .habilidades .verMas:hover {
        color: #4796FC;
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
        font-family: Helvetica;
}
.detalles p{
    margin: 0;
}

.eventos.detalle .content .detalles img{
    margin: 0 20px 0 10px;
}

.eventos.detalle .content .detalles p{
    font-size: 14px;
}

.eventos.detalle .content .detalles p.subTitulo{
    font-size: 16px;
    font-weight: normal;
    margin: 0;
}

.eventos.detalle .content .detalles > p.subTitulo{
    font-weight: bold;
}

.medallaTitle{
    margin-top: 1.5%;
}

.detalles > div{
    justify-content: flex-start;
    margin: 2.25% 0;
}

.eventos.detalle .content .detalles img{
    margin: 0 20px 0 0px;
}

.job-actions button:first-child, .job-actions.asistirEvento p{
    margin: 2.5% 0 0;
}

.job-actions.asistirEvento{
    margin-bottom: 0;
    padding: 5% 0;
}

.EventoBlock p:not(.afinidad) span.job-span-location{
    margin: 4px 0 2.5px;
    width: 100%;
}

.dates-event-container p:not(:first-of-type) {
    width: 100%;
    margin: 12.5px 0 12.5px 34px;
}

@media only screen and (max-width: 812px) {

    .dates-event-container p:not(:first-of-type) {
        margin: 12.5px 0 12.5px 44px;
    }

    .eventos.detalle .distancia{
        font-size: 14px;
        margin-left: 22.5px;
    }

    .VerMas {
        top: 35px;
    }

    .VerMas span{
        display: inline;
        font-size: 14px;
    }

    .EventoBlock .VerMas {
        top: 45px;
    }

    .EventoBlock .VerMas span{
        display: inline;
        font-size: 14px;
    }

    .eventos:not(.detalle){
        width: 100%;
    }

    .eventos.detalle .content .medallaContent{
        margin: 0.5% 0 5%;
    }

    .eventos.detalle .content p.subTitulo{
        margin: 6% 0 0.75%;
    }

    .eventos.detalle .content .detalles p.subTitulo{
        margin: 0;
    }

    .job-actions.asistirEvento button:first-child, .job-actions.asistirEvento p{
        margin-bottom: 3.25%;
    }

    .detalles > div{
        margin: 5% 0;
    }

    .eventos.detalle .content .detalles img{
        margin: 0 20px 0 10px;
    }

    .job-actions.asistirEvento{
        padding: 7.5% 0;
    }

    .eventos .filterBox {
        padding: 3% 1.5% 3% 4%;
        margin-top: 15px;
    }

    .eventos .filtroIcon {
        right: 10px;
        top: 25px;
    }

    .EventoBlock{
        padding: 3.5% 2.5% 1.5%;
    }

    .EventoBlock h3 {
        font-size: 15px;
        margin: 0 0 9px;
    }

    .EventoBlock p {
        font-size: 13.5px;
        margin: 0 0 4px;
    }

    .EventoBlock span {
        font-size: 12.25px;
        margin: 0 0 4px;
    }

    .EventoBlock p:not(.afinidad) span {
        display: block;
        margin: 4px 0 0;
    }

    .EventoBlock .afinidad {
        font-size: 13px;
        width: 100%;
    }

    .eventos .legend {
        font-size: 15px;
    }

    .AfinidadIndicador span{
        margin-right: 7px;
        margin-bottom: 0;
    }

    .EventoBlock figure{
        width: 50px;
        min-width: 50px;
        height: 50px;
    }

    .EventoBlock p:not(.afinidad) .VerMas{
        position: relative;
        top: auto;
        right: auto;
    }

    .EventoBlock p:not(.afinidad) .VerMas span{
        margin: 0;
    }

    .EventoBlock p:not(.afinidad) .VerMas i{
        font-size: 16px;
    }

    .EventoBlock p:not(.afinidad) span.job-span-location{
        margin: 5px 0 2.5px;
    }
}