.container:not(.profile-container.active, .path-container.active){
    padding-top: 137.5px;
}

.container.medals-page-container{
    padding-top: 137.5px;
}

.container.messages-container.inside-conversation{
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
}

.container h4{
    font-weight: bold;
    margin-top: 0;
    font-size: 20px;
}

.conversation figure{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
}

.conversation > div {
    margin-left: 18px;
}

.conversation{
    padding: 2.25% 0 2%;
    border-bottom: 1px solid rgba(0,0,0,0.125);
    color: inherit;
    cursor: pointer;
}

.conversation > div > div{
    padding: 7.5px 0 3px;
}

.conversation > div > div h4{
    margin-left: 7.5px;
    margin-bottom: 0;
    font-size: 19px;
    font-weight: normal;
}

.message-text-preview{
    color: rgba(0,0,0,0.65);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
    margin: 0;
    padding-bottom: 1px;
    font-size: 14.5px;
}

.conversation span{
    font-size: 10.5px;
    color: rgba(0,0,0,1);
    font-family: Montserrat;
}

.conversations-container, .no-messages-container{
    height: auto;
    display: none;
}

.conversations-container.show{
    display: block;
    margin: 2.5% 0 0;
}

.no-messages-container.show{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 5% 0 0;
}

.no-messages-container.show > p{
    font-weight: bold;
    font-family: Montserrat;
    font-size: 20px;
    width: 65%;
    text-align: center;
}

.no-messages-container.show .warning-message{
    padding: 0 15px;
    font-size: 12.5px;
    margin: 4% auto 0;
}

.no-messages-container.show .warning-message p{
    margin-left: 5%;
}

.inside-conversation{
    background: #F7F7F7;
    min-height: 100vh;
    height: auto;
    overflow: auto;
    margin-bottom: 50px;
}


.inside-conversation .inside-message:first-child .conversation{
    padding: 0 0 2%;
}

.inside-conversation .conversations-container.show{
    margin: 0;
}

.inside-conversation .conversation span{
    font-size: 13px;
    display: block;
    margin-bottom: 0;
}

.inside-conversation .message-text-preview{
    text-overflow: unset;
    white-space: normal;
    overflow: auto;
    max-width: none;
    padding: 0 0 2%;
    border-bottom: 1px solid rgba(0,0,0,0.125);
}

.inside-conversation .conversation > div > div{
    padding: 0px 0 3px;
}

.inside-conversation .conversation{
    border-bottom: none;
}

.show-more-messages{
    font-weight: bold;
    padding-bottom: 3%;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    border-bottom: 1px solid rgba(0,0,0,0.125);
}

.show-more-messages span{
    color: #F2C714;
    font-size: 20px;
    margin-left: 10px;
}

.faj-message textarea{
    width: 100%;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.275);
    padding: 2.5% 2.5% 15%;
}

button:disabled,
button[disabled]{
    opacity: 0.5;
}

#file-input{
    display: none;
}

.text-field-message{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.text-field-message label{
    color: #F2C714;
    font-size: 18px;
    background: #fff;
    margin-bottom: 0;
    padding: 12px 10px 12px 10px;
    height: 50px;
}

.text-field-message label.disabled {
    color: #D3D3D3;
}

.text-field-message textarea{
    flex: 1;
    padding: 14.5px 0 14.5px 10px;
    border: none;
    height: 50px;
    font-size: 16px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3), 0 1px 2px rgba(0,0,0,0.3);
}

.text-field-message textarea::-webkit-input-placeholder { /* WebKit browsers */
    font-size: 13.75px; 
}

.text-field-message textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-size: 13.75px;
}

.text-field-message textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
     font-size: 13.75px;
}

.text-field-message textarea:-ms-input-placeholder { /* Internet Explorer 10+ */
    font-size: 13.75px;
} 

.text-field-message textarea:focus, .faj-message textarea:focus{
    outline: none;
}


@media only screen and (max-width: 812px) {
    .container:not(.profile-container.active, .path-container.active){
        padding-top: 127.5px;
    }

    .no-messages-container.show .warning-message{
        margin: 7.5% auto 5%;
    }

    .container.medals-page-container{
        padding-top: 127.5px;
    }

    .conversation{
        padding: 4.5% 0 2%;
    }

    .conversations-container.show{
        margin: 5% 0 15%;
    }

    .container h4{
        font-size: 18px;
    }

    .no-messages-container.show{
        padding: 15% 0 7.5%;
    }

    .container.messages-container.inside-conversation{
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .inside-conversation .message-text-preview{
        padding: 2% 0 3%;
    }

    .inside-conversation .conversations-container.show{
        padding: 0 4%;
    }

    .text-field-message label{
        flex-basis: 10%; 
    }
}