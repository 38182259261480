.registro .infoTop {
    background-color: #000;
    padding-top: 50px;
    padding-bottom: 0;
    position: relative;
}
.registro .infoTop h1{
    margin: 0;
    padding: 0;
    color: #F2C714;
    font-family: Montserrat;
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 12.5px;
}
.registro .infoTop p{
    color: #fff;
    font-family: Helvetica;
    font-size: 14px;
    text-align: center;
}
.registro .infoTop .numbers {
    display: flex;
    justify-content: space-around;
    position: relative;
    top: 20px;

}
.registro .numbers > div {
    width: 35px;
    height: 35px;
    background-color: #F9F9F9;
    color: #000;
    border-radius: 20px;
    text-align: center;
    padding-top: 7px;
    font-weight: bold;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    outline: none;
}
.registro .numbers > div.active {
    background: #F2C714;
}
.registro .numbers > div.checked {
    text-indent: -9999px;
    background-color: #F2C714;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAANCAYAAAEBGQTXAAAABGdBTUEAALGPC/xhBQAAAXtJREFUKBWFkjFLw1AQx/OSqK0dRXDsVxC6CU1DQegiKBbRoaJDFkH8CF3EwbWbiIhUHAQRcVEIdnHrLP0CDh0Eqz5xaOLvgglJrXhw5P73v7v/vfdiGD+mKpXKThiGh3HCMBzHORek4hQldhAErxEGrMZE5lutVmeSBDPeI0AQ4jphRoNEJU2wkCz2Rq6gaP8iuOh0Opu1Wm1Ka/0CVuBpaYomlMvlDaVUG6whCkL8a67remP1IY458bZZr9cn02NY6koIy7J27X6//8yms+jKGX1il+KG7/tnsrWGCEg+kSzRsQRxI9MiTSkgzlPkPmBCZAytUiYBGLvtaFEaM6TFCmuo2Faa+CtuNpsmxe1isXhK44LUgfflPeaID1h1a7TZ87yJXq93Td7B5VnkbHu8xBFfQ24jlAD7wG+54PVut5sbDAb34Hk8j3+i1EDgkjix+LZEtYXLdPm5bDyHa652mau9I/5lmQtDdWU4HJ6YpmnhizQ9/upIJb4BmkaKDmmMwx4AAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    background-position: 10px;
}