.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    padding: 18px;
    z-index: 100;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0, 0.5);
    /* filter: blur(8px);
    -webkit-filter: blur(8px); */
    backdrop-filter: blur(8px);
    overflow-y: scroll;
}

.modal {
    background-color: #FFFFFF;
    width: 62.5%;
    margin: 0 auto;
    /* height: 100%; */
    cursor: auto;
    filter: none;
    -webkit-filter: none;
    padding: 20px 20px 40px; 
    margin-bottom: 18px;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 1px solid rgba(0,0,0,1);
}

.header {
    position: relative;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #F2C714;
    padding-bottom: 10px;
}

.content {
    padding: 24px 8px 0;
}

.button {
    background-color: #F2C714;
    border: none;
    border-radius: 9px;
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    color: #1A1A1A;
    height: 52px;
    margin-top: 10px;
    font-weight: bold;
    font-family: Montserrat;
}

.header h2 {
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
    color: #000000;
    margin: 0;
}

.close {
    width: 100%;
    cursor: pointer;
}

.content section {
    padding: 0px 21px 37px 30px;
    position: relative;
}

.sectionIcon {
    position: absolute;
    height: 16px;
    /* width: 20px; */
    left: 0px;
}

.content section h3 {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 3px 0 10px;
}

.content label {
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}

.content input[type="checkbox"] {
    margin-right: 10px;
    margin-top: 0;
    vertical-align: bottom;
    /* font-size: 18px;
    width: 18px;
    height: 18px; */
}

/* .content input[type="checkbox"]:after {
    content: " ";
    margin-right: 10px;
    border: 2px solid #8D8D8D;
    background-color: #FFFFFF;
    display: inline-block;
    visibility: visible;    
    width: 18px;
    height: 18px;
} */

.chipsWrapper {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 16px;
}

.chip {
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    min-width: 77.5px;
    cursor: pointer;
    border: 1px solid #1A1A1A;
    margin: 4px 0;
    margin-right: 7px;
    padding: 15px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.175), 0 1px 2px rgba(0,0,0,0.25);
}

.chip.selected {
    background-color: #1A1A1A;
}

.chip span {
    color: #1A1A1A;
    font-size: 12px;
    line-height: 14px;
}

.chip.selected span{
    color: #FFFFFF;
}

.sliderContainer {
    width: 100%;
}

.sliderContainer p {
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 11px;
}

.sliderLabels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 6px;
}

.sliderLabels span {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
}

.closeFiltersX{
    position: relative;
    width: 3%;
}

.closeFiltersX::after{
    content: "x";
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 1px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

.experienceContainer{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

.experienceContainer div{
    flex-basis: 25%;
    white-space: nowrap;
    margin: 2% 0 0 0;
}

.experienceContainer input {
    border: none;
    width: 52.5%;
    border-bottom: 2px solid #cccccc;
    margin: 0 10px 0 20px;
    text-align: center;
}

.experienceContainer input:focus {
    outline: none;
    border-bottom-color: #F2C714;
}

@media only screen and (max-width: 812px) {
    .modal {
        width: 70%;
        margin: 0 auto;
    }

    .closeFiltersX{
        width: 4.75%;
    }

    .closeFiltersX::after{
        top: 0.5px;
        font-size: 12.25px;
    }

    .experienceContainer div{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 420px) {
    .modal {
        width: 100%;
        padding: 20px 10px 40px;
    }

    .content section {
        padding: 0px 21px 37px 21px;
    }

    .backdrop {
        padding: 15px 12px;
    }

    .closeFiltersX{
        width: 7%;
    }

    .closeFiltersX::after{
        top: 0.5px;
        font-size: 12.25px;
    }

    .experienceContainer div{
        flex-basis: 42.5%;
    }

    .experienceContainer input {
        margin: 0 12.5px;
        width: 55%;
    }
}

@media only screen and (max-width: 375px) {
    .closeFiltersX::after{
        top: 0px;
    }
}
