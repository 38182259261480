.ingreso{
    height: 100vh;
}

.ingreso .info-top {
    position: relative;
    padding: 35px;
    background-color: #FFFFFF;
    text-align: center;
    min-height: 250px;
    max-height: 250px;
}
.ingreso img {
    margin: 5px auto 10px;
}
.ingreso h1 {
    font-family: Montserrat;
    font-size: 33px;
    color: #F2C714;
    text-align: center;
    font-weight: bold;
    margin: 0;
}
.ingreso p {
    /*max-width: 90%;*/
    text-align: center;
    font-size: 13px;
    font-family: Helvetica;
}

.arrow{
    background-color: #F2C714;
    border-radius: 50%;
    color: #000;
    position: absolute;
    top: -12.5px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 30px;
    height: 30px;
    padding-top: 6px;
    text-align: center;
}

.blackContainer{
    position: relative;
    background: #1a1a1a;
    height: calc(100% - 250px);
    padding: 8% 0 0;
}

.button{
    width: 22.5%;
    background: #F2C714;
    color: #000;
    border-radius: 7px;
    border: none;
    padding: 1.5%;
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 auto;
    display: block;
    text-align: center;
}


@media only screen and (max-width: 420px) {
    .ingreso .info-top {
        padding: 35px 35px 20px;
        min-height: 230px;
        max-height: 230px;
    }

    .button{
        width: 60%;
        padding: 4% 0;
    }

    .blackContainer{
        height: calc(100% - 230px);
        padding: 18% 0 0;
    }
}
