.bottomTools {
    display: flex;
    position: fixed;
    justify-content: space-around;
    align-items: flex-start;
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    bottom: 0px;
    left: 0px;
}

.bottomTools a:active,
.bottomTools a:focus,
.bottomTools a:hover,
.bottomTools a.active,
.bottomTools a{
    text-align: center;
    color: white;
    padding: 16.75px 0;
    flex-basis: 25%;
    border-top: 3px solid #000000;
}

.bottomTools a.active{
    border-top: 3px solid #F2C714;
}


/* .bottomTools a.active {
} */
