@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap-theme.min.css');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css');

body, html {
    font-family: Helvetica, sans-serif;
    padding: 0;
    margin: 0;
}

h1 {
    font-family: Montserrat;
}