.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 100;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0, 0.5);
    /* filter: blur(8px);
    -webkit-filter: blur(8px); */
    backdrop-filter: blur(8px);
    /*overflow: scroll;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: #FFFFFF;
    width: 50%;
    cursor: auto;
    filter: none;
    -webkit-filter: none;
    padding: 40px 15px 37px; 
    margin-bottom: 18px;
    border: 1px solid #979797;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}

.modal h1 {
    font-size: 20px;
    line-height: 24px;
    color: #1A1A1A;
    text-align: center;
    margin: 23px 0;
    font-weight: bold;
}

.modal p {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-align: center;
    margin: 0;
    width: 90%;
}

.buttonsContainer {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.buttonsContainer button {
    background-color: #F2C714;
    border: none;
    width: 20%;
    height: 37px;
    font-size: 12px;
    color: #1A1A1A;
    line-height: 14px;
    text-transform: uppercase;
    margin: 0 6px;
    font-weight: bold;
    border-radius: 3px;
    font-family: Montserrat;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}

.buttonsContainer button.secondary {
    background-color: #FFFFFF;
    color: #1A1A1A;
    border-color: #1A1A1A;
}

.optionsWrapper {
    padding-top: 21px;
}

.optionsWrapper label{
    font-size: 16px;
    line-height: 20px;
    color: #3F3F3F;
    padding-bottom: 10px;
    display: block;
    text-align: center;
}

.optionsWrapper label input {
    margin-right: 10px
}

@media only screen and (max-width: 812px) {
    .buttonsContainer button {
        width: 25%;
    }
    .modal {
        width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    .buttonsContainer button {
        width: 45%;
    }
}