.flex-column{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column wrap;
    align-items: center;
}

.container.medals-page-container, .container.path-container:not(.active){
    padding-top: 137.5px;
}

.container.profile-container.active, .container.path-container.active{
    padding-top: 0px;
}

.profile-general-information{
    margin: 4% 0 0;
}

.profile-general-information h3{
    font-weight: bold;
    font-size: 22px;
}

.profile-general-information .location{
    margin: 0 auto;
}

.profile-general-information .location span {
    margin-left: 5px;
}

.profile-general-information > figure{
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid rgba(0,0,0,0.5);
    width: 185px;
    height: 185px;
    margin: 0 auto;
}

.profile-user-state, .medal-content, .bullying-card{
    font-family: Montserrat;
}

.profile-container{
    background: #F9F9F9;
}
.profile-container.active, .path-container.active{
    width: 100%;
}
.container.path-container.active{
    padding: 0 2.75% 12.5%;
}
.card{
    background: #fff;
    border-radius: 4px;
    padding: 2.5% 2.25%;
    margin: 2.75% 1.5%;
}

.card h5{
    margin: 0 0 3%;
    font-weight: bold;
    border-bottom: 1px solid #F2C714;
    padding-bottom: 1%;
    font-family: Montserrat;
    font-size: 16px;
}

.card h5 span{
    font-weight: normal;
    margin-left: 2.5%;
}

.card-portrait{
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.profile-card-content > div.user-info{
    margin: 0 4.5%;
}

.profile-card-content figure:last-child{
    border-radius: 50%;
    border: 1px solid #F2C714;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 10%;
    box-sizing: border-box;
    padding: 2.25% 0;
    margin-left: 9.5%;
}

.profile-card-content figure:last-child img{
    width: 50%;
}

.medal-image{
    max-width: 45px;
    margin-bottom: 12.5px;
}

.medal-image img{
    width: 100%;
}

.medal-content{
    margin-bottom: 5%;
    font-size: 16px;
    text-align: center;
    flex-basis: 31%;
    margin: 0 1.25%;
    padding: 1.75% 0;
}

.bullying-card .medallaTitle{
    padding: 4% 0 1.5%;
}

.bullying-card img{
    margin: 0 10px;
}

.bullying-card .medallaContent{
    padding-left: 34px;
}

.profile-card.list-card ul {
    list-style: none; /* Remove default bullets */
    padding-left: 20px;
}

.profile-card.list-card ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #F2C714; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */ 
    width: 1.5em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.profile-card.list-card.horizontal-list-card ul li::before {
    width: 1em;
}

.vertical-list-card ul, .horizontal-list-card ul{
    font-size: 18px;
}

.vertical-list-card ul li{
    margin-bottom: 2%;
}

.horizontal-list-card ul li:not(:first-child){
    margin-left: 40px;
}

.horizontal-list-card{
    padding: 1% 0 1.5%;
}

.vertical-list-card ul li p{
    margin: 1.5% 0 1.5% 3.5%;
}

.languages-card figure{
    width: 28%;
    flex-basis: 28%;
}

.languages-card figure img{
    width: 100%;
}

.languages-card > div{
    width: 100%;
}

.languages-card > div > div{
    flex-basis: 33%;
}

.languages-card > div > div span{
    margin: 0 0 0 7.5px;
    font-size: 17px;
}

.profile-card > p{
    font-size: 16px;
    font-family: Montserrat;
}

.profile-general-information .container.profile-container, .profile-general-information .container.path-container{
    display: none;
    
}

.container.profile-container, .container.path-container{
    padding-bottom: 12.5%;
}

.container.profile-container.active, .container.path-container.active{
    display: block;
}

.path-search input{
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.5);
    padding: 1.25% 1.5% 1.25% 2%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}

.path-search{
    margin: 2.5% auto 2%;
    position: relative;
}

.path-search figure{
    position: absolute;
    top: 10px;
    left: 10px;
}

.path-buttons-container button{
    flex-basis: 30%;
    border: 1px solid rgba(0,0,0,0.75);
    border-radius: 3px;
    padding: 1.5% 0;
    background: #fff;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}

.path-buttons-container button.active{
    border: none;
    background: #F2C714;
}

.path-buttons-container{
    margin: 0 auto 3%;
}

.path-container{
    background: #F9F9F9;
}

.path-container .PuestoBlock .PuestoBlockContent{
    margin-left: 17.5px;
}

.path-container .PuestoBlock figure{
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 3px;
}

.path-container .PuestoBlock .VerMas, .path-container .PuestoBlock .afinidad{
    display: none;
}

.path-container .PuestoBlock h3, .path-container .PuestoBlock p:last-of-type{
    font-family: Montserrat;
}

.path-container .PuestoBlock p:first-of-type{
    font-size: 13.5px;
    margin-bottom: 5px;
}

.path-container .PuestoBlock p.caption-event{
    font-family: Helvetica, sans-serif;
    color: #999999;
    font-size: 13px;
    letter-spacing: 0.25px;
}

.path-container .PuestoBlock p{
    color: #3F3F3F;
    font-size: 13.75px;
    margin: 0.75% 0;
}

.path-container .PuestoBlock h3{
    font-size: 16px;
}

.path-container .PuestoBlock{
    padding: 1% 0 0;
}

.path-container .PuestoBlock span{
    font-weight: bold;
    font-size: 13.5px;
    letter-spacing: 0.75px;
}

.path-container .PuestoBlock span i{
    margin: 0 0.75% 0 0;
}

.jobs-events-container{
    background: #fff;
    padding: 1% 3.5%;
    margin: 0 auto;
}

.path-container .PuestoBlock hr{
    margin: 2% 0 0;
}

.card .profile-general-information > figure, .card .profile-general-information{
    margin: 0;
}

.card .profile-general-information{
    padding: 2% 0;
}

.edit-image > div{
    margin-left: 7.5%;
    font-family: Montserrat;
}

.card button{
    background: #F2C714;
    border-radius: 5px;
    padding: 6.25% 2.5%;
    border: none;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
}

.custom-file-upload {
    background: #F2C714;
    border-radius: 5px;
    padding: 6.25% 2.5%;
    border: none;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

.custom-file-upload input[type="file"] {
    display: none;
}

.card.edit-info p{
    margin: 0;
    font-family: Montserrat;
}

.card.edit-info i{
    font-size: 26px;
}

.card.edit-info > div > div{
    margin-left: 15px;
    flex: 1;
    margin-bottom: 18px;
}

.card.edit-info > div:last-child i{
    font-size: 22px;
}

.card.edit-info input, .edit-contact-info select, .edit-contact-info input{
    border: none;
    border-bottom: 1px solid black;
    padding: 1.25% 1.5%;
    width: 90%;
}

.card.edit-contact-info.map-container > div:first-of-type{
    height: 175px !important;
}

.edit-contact-info select, .edit-contact-info input{
    margin: 1% 0;
    width: 100%;
}

.selects-container{
    padding: 5% 0 0;
}

.edit-contact-info > div.selects-container div:last-child{
    padding: 5% 0 0;
}

.edit-contact-info > div.selects-container div:last-child p{
    padding: 0 3.5%;
    color: #8A8A8A;
    font-weight: bold;
}

.edit-contact-info p{
    font-weight: bold;
    font-size: 13px;
    color: #999999;
}

.submit-button{
    background: #F2C714;
    border-radius: 5px;
    padding: 2%;
    border: none;
    width: 50%;
    font-weight: bold;
    text-transform: uppercase;
    margin: 7.5% auto; 
    display: block;
}

.big-medal-container{
    width: 90%;
    margin: 0 auto 3.5%;
    background: #fff;
    border: 1px solid #F2C714;
    border-radius: 2px;
    padding: 3% 5%;
}

.big-medal-container h3{
    margin: 0 0 2.75%;
    font-size: 18px;
    font-weight: bold;
}

.big-medal-container p{
    margin: 0 0 2.75%;
    font-size: 12.75px;
}

.big-medal-container span{
    color: #8A8A8A;
    font-size: 11.5px;
}

.big-medal-container > div{
    margin-left: 22.5px;
}

.big-medal-container img{
    width: 6.75%;
}

.edit-profile-button a, .see-more-button a{
    color: #4997FC;
    font-weight: bold;
    cursor: pointer;
}

.edit-profile-button a:hover, .see-more-button a:hover{
    text-decoration: none;
    color: #F2C714;
}

.edit-profile-button, .see-more-button{
    text-align: right;
    padding: 1.5% 3%;
}

.see-detail-event{
    margin: 2.5% 0 0;
}

.edit-profile-button i{
    margin: 0 0.75%;
}

.profile-user-state select{
    border: none;
}

.profile-user-state{
    margin-bottom: 0.75%;
}

.block-distance{
    margin-left: 16px;
    display: block;
}

.add-language-button, .remove-lang-button {
    color: #4E9AFC !important;
    cursor: pointer;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    font-weight: bold !important;
}

.path-search, .path-buttons-container, .jobs-events-container{
    width: 82.5%;
}

.registroNuevoIdioma .form-subtitle{
    font-weight: bold;
    font-family: Montserrat;
    color: #000;
    font-size: 15px;
}

.course-title{
    margin: 1.5% 0;
}

.edit-contact-info input{
    padding: 0.75% 1.5%;
    margin: 0.75% 0;
}

@media only screen and (max-width: 812px) {

    .path-container .PuestoBlock span i{
        margin: 0 1.75% 0 0;
    }

    .path-search, .path-buttons-container, .jobs-events-container{
        width: 100%;
    }

    .container.medals-page-container, .container.path-container:not(.active){
        padding-top: 127.5px;
    }

    .profile-user-state{
        margin-bottom: 2.25%;
    }

    .profile-general-information{
        margin: 15% 0 0;
    }

    .profile-general-information > figure{
        width: 105px;
        height: 105px;
        margin: 0 auto;
        border: 1px solid rgba(0,0,0,0.75);
    }

    .card-portrait{
        width: 57.5px;
        height: 57.5px;
    }

    .profile-card p{
        margin: 0;
    }

    .path-container .PuestoBlock{
        padding: 2% 0;
    }

    .medal-image{
        max-width: 32.5px;
        margin-bottom: 7.5px;
    }

    .medal-content{
        margin-bottom: 5%;
        font-size: 12px;
        text-align: center;
        flex-basis: 31%;
        margin: 0 1.25%;
        padding: 1.75% 0;
    }

    .big-medal-container{
        width: 90%;
        margin: 0 auto 3.5%;
        background: #fff;
        border: 1px solid #F2C714;
        border-radius: 2px;
        padding: 6% 5%;
    }

    .big-medal-container img{
        width: 12.5%;
    }

    .card{
        padding: 4%;
        margin: 5% 0;
    }

    .card h5{
        margin: 0 0 4%;
        padding-bottom: 2%;
        font-size: 14px;
    }

    .vertical-list-card ul li{
        margin-bottom: 3%;
    }

    .horizontal-list-card {
        padding: 2.5% 0 1.5%;
    }

    .path-search {
        margin: 5% 0 3%;
    }

    .path-search input{
        padding: 2.25% 1.5% 2.25% 4%;
        width: 100%;
    }

    .path-buttons-container button{
        flex-basis: 32%;
        padding: 2% 0;
    }

    .container.path-container.active{
        padding: 0 2.75% 12.5%;
    }

    .edit-profile-button i{
        margin: 0 1.75%;
    }

    .edit-contact-info select, .edit-contact-info input{
        margin: 2.5% 0;
        width: 100%;
    }

    .submit-button{
        padding: 5% 2.5%;
        width: 70%;
    }

    .jobs-events-container{
        padding: 5% 4.5%;
    }

    .path-container .PuestoBlock p:first-of-type{
        font-size: 13.5px;
        margin-bottom: 7.5px;
    }

    .path-container .PuestoBlock p{
        font-size: 13.75px;
        margin: 2.25% 0 1.25%;
    }

    .path-container .PuestoBlock hr{
        margin: 7.5% 0 0;
    }
}